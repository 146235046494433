<template>
  <div>
    <div class="flex justify-items-center" v-if="viewAlert">
      <vs-alert
        :title="$t('alerta')"
        color="rgb(231, 154, 23)"
        active="true"
        class="mt-5 text-warning mb-4 h-auto">
        <p>
          {{ $t('document_person.required_description') }}
        </p>
          <div class="w-full grid justify-items-center ">
            <vs-button
              @click="openPopup"
              type="flat"
              class="float-left mt-1 hover:scale-110">
              {{ $t('document_person.touch_here_to_start') }}
            </vs-button>
          </div>
        </vs-alert>
        <DocumentQrCodeUploadPopup
            :qrCodeData="qrCodeData"
            :visible="showPopup"
            @close="closePopup"/>
    </div>

  </div>
</template>
<script>

import { isAdmin } from '@/util/Util'

import DocumentQrCodeUploadPopup from './DocumentQrCodeUploadPopup.vue'

import DocumentPersonService from '@/services/api/DocumentPersonService'

export default {
  directives: {

  },
  components: {
    DocumentQrCodeUploadPopup,
  },
  props: {
    facematch :{
      type: Boolean,
      default: false
    },
    liveness :{
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    service: null,
    showPopup: false,
    viewAlert: true,
    existsDocFaceMatch: false,
    qrCodeData: null
  }),
  computed: {

  },
  beforeMount() {
    this.service = DocumentPersonService.build(this.$vs)
  },
  mounted() {
    if(this.facematch){
      //this.existsDocument('doc-photo')
      //this.existsDocument('face-photo')
    }
    if(this.liveness){
      //this.existsDocument('liveness-video')
    }

    this.createEchoServer()
  },
  methods: {
    loadData() {
      this.$vs.loading()
      const docType = this.handlingDocType();
      console.log('handlingDocType to get QRCode...'+docType)
      this.service.getQrCodeDataToUploadDocument(docType).then(
        response => {
          this.qrCodeData = response
        },
        error => {}
      ).finally(() => {this.$vs.loading.close()})
    },
    existsDocument(docType) {
      this.$vs.loading()
      this.service.existsDocument(docType).then(
        response => {
          this.populateModels(docType, response)
        },
        error => {}
      ).finally(() => {this.$vs.loading.close()})
    },
    populateModels(docType, response){
      console.log(docType+' Exists: '+response.exists)
      this.showPopup = !response.exists
    },
    handlingDocType(){
      if(this.facematch && this.liveness){
        return 'both'
      }else if(this.liveness){
        return 'liveness-video'
      }else {
        return 'doc-photo'
      }
    },
    openPopup() {
      this.loadData()
      this.showPopup = true
    },
    closePopup(show) {
      this.showPopup = show
    },
    isAdmin() {
      return isAdmin()
    },
    createEchoServer(){
      if (this.socketEnabled()) {
        const userId = this.$store.getters.userId
        console.log('window.Echo...', window.Echo, userId);
        if (window.Echo) {
          window.Echo.private(`App.Models.User.${userId}`).notification(
              (notification) => {
                this.handleIncomingNotification(notification);
              }
            )
        }
      }
    },
    handleIncomingNotification(notification){
      console.log('Notification In Document Person On Board...', notification.type);

      switch (notification.type) {
        case 'document.validated':
          this.notifySuccess(this.$vs, this.$t('document_person.valided_success'), 5000)
          setTimeout(() => {window.location.reload()}, 5000)
          break;
        case 'document.inValidation':
          this.notifySuccess(this.$vs, this.$t('document_person.in_validation'), 15000)
          break;
        case 'document.notValidated':
          this.notifyWarning(this.$vs, this.$t('document_person.not_valided'), 10000, 'top-center')
          this.closePopup(false)
          break;
        default:
          this.notifyError(this.$vs, this.$t('document_person.validation_error'), 10000, 'top-center')
          this.closePopup(false)
          break;
      }
      // this.service.handleIncomingNotification(notification)
    }
  }
}
</script>

<style>

</style>
