var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-row",
    [
      _c(
        "vs-col",
        {
          attrs: {
            "vs-type": "flex",
            "vs-justify": "center",
            "vs-align": "center",
            "vs-w": "12",
          },
        },
        [
          _c("div", { attrs: { id: "clockdiv" } }, [
            _vm.showDays
              ? _c("div", [
                  _c("span", { staticClass: "days" }, [
                    _vm._v(_vm._s(_vm.days)),
                  ]),
                  _c("div", { staticClass: "smalltext" }, [
                    _vm._v(_vm._s(_vm.$t("dias"))),
                  ]),
                ])
              : _vm._e(),
            _vm.showHours
              ? _c("div", [
                  _c("span", { staticClass: "hours" }, [
                    _vm._v(_vm._s(_vm.hours)),
                  ]),
                  _c("div", { staticClass: "smalltext" }, [
                    _vm._v(_vm._s(_vm.hourLabel)),
                  ]),
                ])
              : _vm._e(),
            _c("div", [
              _c("span", { staticClass: "minutes" }, [
                _vm._v(_vm._s(_vm.minuts)),
              ]),
              _c("div", { staticClass: "smalltext" }, [
                _vm._v(_vm._s(_vm.$t("min"))),
              ]),
            ]),
            _vm.showSeconds
              ? _c("div", [
                  _c("span", { staticClass: "seconds" }, [
                    _vm._v(_vm._s(_vm.seconds)),
                  ]),
                  _c("div", { staticClass: "smalltext" }, [
                    _vm._v(_vm._s(_vm.$t("seg"))),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }