var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "overseer",
      staticClass: "event-overseer",
      attrs: { tabindex: "0" },
      on: {
        mouseup: _vm.selectTextListener,
        copy: _vm.copyPasteCutHandler,
        paste: _vm.copyPasteCutHandler,
        cut: _vm.copyPasteCutHandler,
        keypress: _vm.keyPressHandler,
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }