<template>
    <vs-popup :title="$t('document_person.send')" :active.sync="visible" id="popup-qrcode" @close="closePopup" ref="vspopup-qrcode">
      <div class="grid items-center flex flex-wrap justify-center justify-items-center gap-1">
        <div class="p-4">
          <p>{{ $t('document_person.qrcode_description') }}</p>
        </div>
        <div>
          <img
            class="border-t-0 border-b-0 border-r-0"
            :src="qrCodeBase64"
            alt="QR Code Document Person Upload"
            style="max-width: 200px; max-height: 200px;"/>
        </div>
        <div>
          <span class="text-sm">{{$t('document_person.experis_at')}}</span>
          <div>
            <count-down :key="countDownQrCode"
              @zero="timeExperisQrCodeValidation"
              :duration="time"></count-down>
          </div>
        </div>
      </div>
      <div class="flex pt-2 items-center text-sm float-right flex-end gap-1">
        <font-awesome-icon icon="lock" class="h-3 w-3"/>
        <span>{{$t('secure_connection')}}</span>
      </div>
    </vs-popup>
</template>

<script>

//Components
import CountDown from '@/components/CountDown.vue'

//Services
import DocumentPersonService from '@/services/api/DocumentPersonService'

export default {
  components: {
    CountDown

  },
  props: {
    visible :{
      type: Boolean,
      default: false
    },
    qrCodeData :{
      type: Object,
      default: null
    },

  },
  data: () => ({
    service: null,
    countDownQrCode: 0,
  }),
  computed: {
    qrCodeBase64(){
      return _.get(this.qrCodeData, 'qrCodeImage')
    },
    startAt(){
      return _.get(this.qrCodeData, 'startAt')
    },
    experisAt(){
      return _.get(this.qrCodeData, 'experisAt')
    },
    time(){
      const start = this.$moment(this.startAt)
      const experis = this.$moment(this.experisAt)
      let duration = this.$moment.duration(experis.diff(start));
      this.countDownQrCode++
      return  duration.asSeconds()
    }
  },
  methods: {
    closePopup(value){
      this.$emit('close', value)
    },
    timeExperisQrCodeValidation(){
      if(this.visible){
        this.notifyTimeExperis()
      }
    },
    notifyTimeExperis(){
      this.$vs.dialog({
          icon: 'icon-alert-circle',
          color: 'warning',
          title: this.$t('atencao'),
          text: this.$t('document_person.token_expiries'),
          acceptText: this.$t('ok-0'),
          accept: () => {this.closePopup(false)}
        })
    },
  },
  beforeMount() {
    this.service = DocumentPersonService.build(this.$vs)
  },
  mounted() {

  },
  unmounted(){

  },
  watch: {

  },
}
</script>


<style lang="scss" scoped>

</style>
