<template>
  <div ref="formAnswer" :class="['form-answer relative min-h-screen', {'active-sidebar': activeSidebar}]">
    <questionnaire-event-overseer
      :screenfull="screenfullInstance"
      :recording="isRecordingEvents"
      @queueSizeChanged="eventQueueSize=$event"
      :answerId="answerId"
      :service="questionnaireAnswerService"
      class="min-h-screen"
    >

      <vs-row class="mt-4" vs-justify="center" v-if="preview">
        <vs-row class="mt-4 overflow-hidden" vs-justify="center" v-if="preview" >
          <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="11">
            <vs-card actionable>
              <div slot="header">
                <vs-alert
                  :title="$t('questionnaire.warn_preview')"
                  color="rgb(231, 154, 23)"
                  active="true"
                  class="text-warning h-auto"
                >
                  {{ $t('questionnaire.warn_preview_full') }}
                </vs-alert>
              </div>
            </vs-card>
          </vs-col>
        </vs-row>
      </vs-row>

      <vs-row class="mt-2" vs-justify="center" v-if="!started">
        <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="11">
          <vs-card v-if="questionnaire !== null">

            <div slot="header">
              <GeneralInformation
                :preview="preview"
                :start_error_code="startErrorCode"
                :start_error="startError"
                :questionnaire="questionnaire"
                :show="{
                  duration_shortage: durationShortage
                }"
                :voiceControlActivated="voiceControlActivated"
                @toggleVoiceControl="voiceControlStateChange"
              />

              <document-person-on-board v-if="isDocumentPersonOnBoard"
                :facematch="_.get(questionnaire, 'facematch', false)"
                :liveness="_.get(questionnaire, 'liveness', false)"></document-person-on-board>

              <!-- description -->
              <div v-if="description" class="editor-content ck-content" id="descriptionElement" v-html-safe="description"></div>
            </div>
          </vs-card>

        </vs-col>
      </vs-row>

      <div v-if="showStart && !started && !startError" class="flex justify-items-center justify-center w-full mb-4 mt-4">

        <div class="col-span-12 flex justify-center">
          <div class="w-full sm:w-auto">
            <vs-input
              v-if="showPassword"
              type="password"
              name="questionnaire_password"
              icon="icon icon-lock"
              icon-pack="feather"
              label-placeholder="Código/Senha"
              v-model="password"
              class="no-icon-border w-full"
              autocomplete="off"
            />
          </div>
        </div>

      </div>

      <div v-if="showStart && !started" class="flex justify-center">

        <div class="flex flex-col gap-2">

          <div class="grid w-full gap-2 auto-cols-auto sm:auto-cols-min sm:justify-center">

            <div
              class="flex gap-2 sm:w-auto"
            >
              <vs-button
                type="filled"
                icon="play_arrow"
                class="footer-button w-full md:w-auto start-questionnaire"
                @click="start"
                :disabled="disableStartButton"
                >{{ this.continue ? $t('continuar') : $t('iniciar') }}</vs-button
              >

              <!-- <vs-button
                type="filled"
                icon="laptop"
                class="footer-button w-full md:w-auto"
                style="min-width: 170px"
                @click="openDesktopApp()"
                v-if="showOpenDesktopApp"
                >Desktop App</vs-button> -->

              <vs-button
                v-if="!started && questionnaire"
                type="border"
                icon="arrow_back"
                class="footer-button w-full md:w-auto"
                @click="$router.push('/')"
                >{{ $t('common.back') }}</vs-button
              >
            </div>

          </div>

          <div v-if="!started" class="w-full flex items-center justify-center gap-2 mt-2">
            <!-- <img src="@/assets/images/logo/logo.png" alt="$t('educatena')" style="max-width: 100px;"/> -->
            <logo alt="$t('educatena')" style="max-width: 100px;"/>
            <img
              class="border-faint-grey border-solid border-l pl-2 border-t-0 border-b-0 border-r-0"
              v-if="organizationLogo"
              :src="organizationLogo"
              :alt="$t('oganization-and-and-organization-name-or-or')"
              style="max-width: 100px;"
            />
            <!-- <logo public style="max-width: 100px;"/> -->
          </div>

        </div>

      </div>

      <div :class="['grid grid-cols-1 sm:grid-cols-12', {'min-h-screen': showSingleQuestion}]" v-if="started && !hasTemplate" ref="parentSidebar">

        <div :class="['pb-20', 'grid col-span-1 mr-4', activeSidebar ? 'sm:col-span-6 md:col-span-6 lg:col-span-9' : 'sm:col-span-12']">

          <vs-row v-if="started && !showSingleQuestion" class="mt-2 items-center justify-center">
            <vs-col class="mr-4 ml-4 mt-2">
                <div>
                  <vs-row v-for="(field, index) in dataFields()" :key="field.id">
                    <vs-col
                      vs-type="flex"
                      vs-justify="left"
                      vs-align="left"
                      vs-w="12"
                      :class="sectionBackground(field)"
                      @click="clickedQuestion"
                    >
                      <SectionAnswer
                        :answer_id="questionnaireAnswer.id"
                        :user_id="user_id"
                        :item="field"
                        :service="questionnaireAnswerService"
                        v-if="field.type === 'section'"
                      ></SectionAnswer>
                      <SimpleTextAnswer
                        :revisionMarked="_.get(bookmarks, field.id, false)"
                        :answer_id="questionnaireAnswer.id"
                        :user_id="user_id"
                        :item="field"
                        :service="questionnaireAnswerService"
                        @revisionMarked="revisionMarked"
                        :show_required_indicator="showQuestionAsRequired(field.id)"
                        :preview="preview"
                        :hide_score="hideQuestionScore"
                        :index="questionNumber(index)"
                        v-if="field.type === 'simple_text'"
                      ></SimpleTextAnswer>
                      <MultipleChoiceAnswer
                        :bus="eventBus"
                        :revisionMarked="_.get(bookmarks, field.id, false)"
                        :answer_id="questionnaireAnswer.id"
                        :user_id="user_id"
                        :item="field"
                        :service="questionnaireAnswerService"
                        @revisionMarked="revisionMarked"
                        :show_required_indicator="showQuestionAsRequired(field.id)"
                        :preview="preview"
                        :index="questionNumber(index)"
                        :hide_score="hideQuestionScore"
                        v-if="field.type === 'multiple_choice'"
                      ></MultipleChoiceAnswer>
                      <CheckBoxAnswer
                        :revisionMarked="_.get(bookmarks, field.id, false)"
                        :answer_id="questionnaireAnswer.id"
                        :user_id="user_id"
                        :item="field"
                        :service="questionnaireAnswerService"
                        @revisionMarked="revisionMarked"
                        :show_required_indicator="showQuestionAsRequired(field.id)"
                        :preview="preview"
                        :index="questionNumber(index)"
                        :hide_score="hideQuestionScore"
                        v-if="field.type === 'check_box'"
                      ></CheckBoxAnswer>
                      <GapAnswer
                        :revisionMarked="_.get(bookmarks, field.id, false)"
                        :answer_id="questionnaireAnswer.id"
                        :user_id="user_id"
                        :item="field"
                        :service="questionnaireAnswerService"
                        @revisionMarked="revisionMarked"
                        :show_required_indicator="showQuestionAsRequired(field.id)"
                        :preview="preview"
                        :hide_score="hideQuestionScore"
                        :index="questionNumber(index)"
                        v-if="field.type === 'gap'"
                      ></GapAnswer>
                      <EssayAnswer
                        :revisionMarked="_.get(bookmarks, field.id, false)"
                        :answer_id="questionnaireAnswer.id"
                        :user_id="user_id"
                        :item="field"
                        :service="questionnaireAnswerService"
                        @revisionMarked="revisionMarked"
                        :show_required_indicator="showQuestionAsRequired(field.id)"
                        :preview="preview"
                        :hide_score="hideQuestionScore"
                        :index="questionNumber(index)"
                        v-if="field.type === 'essay'"
                      ></EssayAnswer>
                    </vs-col>
                  </vs-row>
                </div>
            </vs-col>
          </vs-row>

          <vs-row v-if="started && showSingleQuestion" :key="key" class="mt-2 items-center justify-center min-h-screen">
            <vs-col class="mr-4 ml-4 mt-2">
              <vx-card class="__answer-card" @click="clickedQuestion">
                  <SectionAnswer
                    :answer_id="questionnaireAnswer.id"
                    :user_id="user_id"
                    :item="presentedField"
                    :service="questionnaireAnswerService"
                    :hide_score="hideQuestionScore"
                    class="flex w-full items-center justify-center"
                    :show_required_indicator="presentedField.showRequiredIndicator"
                    v-if="_.get(presentedField, 'type') === 'section'"
                    ref="currentSingleQuestion"
                  ></SectionAnswer>
                  <SimpleTextAnswer
                    :revisionMarked="_.get(bookmarks, presentedField.id, false)"
                    :answer_id="questionnaireAnswer.id"
                    :user_id="user_id"
                    :item="presentedField"
                    :service="questionnaireAnswerService"
                    :hide_score="hideQuestionScore"
                    @revisionMarked="revisionMarked"
                    :disabled-interaction="currentQuestionDisabled"
                    :show_required_indicator="presentedField.showRequiredIndicator"
                    :preview="preview"
                    :index="questionNumber(presentedFieldIndex)"
                    v-if="_.get(presentedField, 'type') === 'simple_text'"
                    ref="currentSingleQuestion"
                  ></SimpleTextAnswer>
                  <MultipleChoiceAnswer
                    :bus="eventBus"
                    :revisionMarked="_.get(bookmarks, presentedField.id, false)"
                    :answer_id="questionnaireAnswer.id"
                    :user_id="user_id"
                    :item="presentedField"
                    :service="questionnaireAnswerService"
                    :hide_score="hideQuestionScore"
                    @revisionMarked="revisionMarked"
                    :disabled-interaction="currentQuestionDisabled"
                    :show_required_indicator="presentedField.showRequiredIndicator"
                    :preview="preview"
                    :index="questionNumber(presentedFieldIndex)"
                    v-if="_.get(presentedField, 'type') === 'multiple_choice'"
                    ref="currentSingleQuestion"
                  ></MultipleChoiceAnswer>
                  <CheckBoxAnswer
                    :revisionMarked="_.get(bookmarks, presentedField.id, false)"
                    :answer_id="questionnaireAnswer.id"
                    :user_id="user_id"
                    :item="presentedField"
                    :service="questionnaireAnswerService"
                    :hide_score="hideQuestionScore"
                    @revisionMarked="revisionMarked"
                    :disabled-interaction="currentQuestionDisabled"
                    :show_required_indicator="presentedField.showRequiredIndicator"
                    :preview="preview"
                    :index="questionNumber(presentedFieldIndex)"
                    v-if="_.get(presentedField, 'type') === 'check_box'"
                    ref="currentSingleQuestion"
                  ></CheckBoxAnswer>
                  <GapAnswer
                    :revisionMarked="_.get(bookmarks, presentedField.id, false)"
                    :answer_id="questionnaireAnswer.id"
                    :user_id="user_id"
                    :item="presentedField"
                    :service="questionnaireAnswerService"
                    :hide_score="hideQuestionScore"
                    @revisionMarked="revisionMarked"
                    :disabled-interaction="currentQuestionDisabled"
                    :show_required_indicator="presentedField.showRequiredIndicator"
                    :preview="preview"
                    :index="questionNumber(presentedFieldIndex)"
                    v-if="_.get(presentedField, 'type') === 'essay'"
                    ref="currentSingleQuestion"
                  ></GapAnswer>
                  <EssayAnswer
                    :revisionMarked="_.get(bookmarks, presentedField.id, false)"
                    :answer_id="questionnaireAnswer.id"
                    :user_id="user_id"
                    :item="presentedField"
                    :service="questionnaireAnswerService"
                    :hide_score="hideQuestionScore"
                    @revisionMarked="revisionMarked"
                    :disabled-interaction="currentQuestionDisabled"
                    :show_required_indicator="presentedField.showRequiredIndicator"
                    :preview="preview"
                    :index="questionNumber(presentedFieldIndex)"
                    v-if="_.get(presentedField, 'type') === 'essay'"
                    ref="currentSingleQuestion"
                  ></EssayAnswer>
                </vx-card>
            </vs-col>
          </vs-row>

          <div v-if="!activeSidebar" class="absolute right-0 h-full">
            <font-awesome-icon
              @click="activeSidebar=true"
              icon="chevron-left"
              class="float-right ml-4 mr-2 cursor-pointer sidebar-left-button"
              v-tooltip.top="{
                content: 'Apresentar barra de questionário'
              }"
            />
          </div>

          <div
            v-if="isSmallScreen && (showSingleQuestion || showSingleSection)"
            class="_form-answer _mobile-navigation-bar"
            :style="mobileNavigationPosition"
          >
            <vx-card class="w-full">
              <question-pagination
                :disable-next="disableNext"
                :disable-previous="disablePrevious"
                :pagination-info="paginationInfo"
                :show-done="doneAnyway"
                @next="nextQuestion"
                @previous="previousQuestion"
              />
            </vx-card>
          </div>

        </div>

        <vs-sidebar :parent="$refs.parentSidebar" default-index="1"  class="sidebar" spacer position-right hidden-background :value="activeSidebar">
          <div :class="['grid col-span-1 mr-4 h-full', activeSidebar ? 'sm:col-span-6 md:col-span-3 lg:col-span-3' : '']">

            <div class="fixed-content">
            <vs-card class="h-full" style="background-color: lightgray;" v-show="activeSidebar">

                <div class="flex flex-col gap-1">

                  <div class="h-8" v-show="activeSidebar">
                    <font-awesome-icon
                      @click="activeSidebar=false"
                      icon="chevron-right"
                      class="cursor-pointer sidebar-right-button float-left"
                      v-tooltip.top="{
                        content: 'Esconder barra de questionário'
                      }"
                    />
                  </div>

                  <div>

                    <vs-card>
                      <div>
                        <table>
                          <tr class="sm:block flex flex-col gap-2" v-if="userName">
                            <td class="font-semibold sm:text-right" style="min-width: 65px;">{{ $t('usuario-0') }}</td>
                            <td class="pl-2">{{truncate(userName)}}<br>{{ userEmail }}</td>
                          </tr>

                          <tr class="sm:block flex flex-col gap-2">
                            <td class="font-semibold sm:text-right" style="min-width: 65px;">{{ $t('questionario-0') }}</td>
                            <td class="pl-2">{{truncate(questionnaireName)}}</td>
                          </tr>
                        </table>
                      </div>

                    </vs-card>

                    <vx-card
                      collapseAction
                      header-style="flex items-start w-1/2 mr-auto"
                      :isContentCollapsedProp="countdownCardCollapsed"
                      v-if="started && questionnaireAnswer.content_questionnaire
                        && questionnaireAnswer.content_questionnaire.duration > 0">

                      <div slot="header" class="font-semibold">
                        {{ $t('cronometro') }}
                      </div>
                      <div>
                        <CountDown
                          @duration="checkQuestionnaireDuration"
                          @zero="() => generalTimeZero = true"
                          :duration="questionnaireDuration"
                          :show_seconds="true"
                          v-if="
                            started &&
                              questionnaireAnswer.content_questionnaire &&
                              questionnaireAnswer.content_questionnaire.duration > 0
                          "
                        ></CountDown>
                      </div>

                    </vx-card>

                    <vx-card
                      collapseAction
                      header-style="flex items-start w-1/2 mr-auto"
                      :isContentCollapsedProp="questionCardCollapsed"
                      class="mt-4"
                    >

                      <div slot="header" class="font-semibold">
                        {{ $t('questoes') }}
                      </div>

                      <div>

                        <div class="flex flex-wrap gap-1">

                          <div class="relative" v-for="(question, i) in dataFields()" :key="`${i}${panelQuestionKey}`">
                            <div :key="panelQuestionKey">
                              <div
                                v-if="question.type !== 'section'"
                                @click="checkQuestionNavigation(question.id, i)"
                                :class="[
                                  questionStatusClass(question),
                                  'grid justify-items-center items-center questionnaire-squares bg-grey cursor-pointer',
                                  {
                                    'questionnaire-square-selected' : i === presentedFieldIndex && showSingleQuestion
                                  }
                                ]"
                              >
                                {{ questionNumber(i) }}
                              </div>
                              <div
                                v-if="question.type === 'section'"
                                @click="checkQuestionNavigation(question.id, i)"
                                :class="[{'questionnaire-square-selected' : i === presentedFieldIndex}, 'grid justify-items-center items-center questionnaire-squares border-yellow cursor-pointer']">S</div>
                            </div>
                            <div class="absolute question-square-bookmark-position" v-if="isBookmarked(question)">
                              <vs-icon
                                icon="bookmark"
                                size="1.20rem"
                                class="revision-color"
                              />
                            </div>
                          </div>
                        </div>

                        <vs-divider />

                        <div class="vx-row sm:flex hidden mt-4">
                          <div class="vx-col w-full flex">
                            <div class="flex flex-wrap justify-center">
                              <div class="flex items-center mr-4 mb-2">
                                <div class="h-3 w-3 inline-block rounded-full mr-2 bg-grey"></div>
                                <span>{{ $t('nao-respondida') }}</span>
                              </div>
                              <div class="flex items-center mr-4 mb-2">
                                <div class="h-3 w-3 inline-block rounded-full mr-2 answer-saved"></div>
                                <span>{{ $t('resposta-salva') }}</span>
                              </div>
                              <div class="flex items-center mr-4 mb-2">
                                <div class="h-3 w-3 inline-block rounded-full mr-2 bg-red-light"></div>
                                <span>{{ $t('nao-salva') }}</span>
                              </div>
                              <div class="flex items-center mr-4 mb-2">
                                <div class="h-3 w-3 inline-block rounded-full mr-2 revision-bg-color"></div>
                                <span>{{ $t('revisao') }}</span>
                              </div>
                              <div class="flex items-center mr-4 mb-2">
                                <div class="h-3 w-3 inline-block rounded-full mr-2 bg-yellow"></div>
                                <span>{{ $t('sessoes') }}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <vs-divider />

                        <vs-row v-if="started && !showSingleQuestion">
                          <vs-col>
                            <question-pagination
                              :reduced="isSmallScreen"
                              :disable-next="disableNext"
                              :disable-previous="disablePrevious"
                              :pagination-info="paginationInfo"
                              :show-done="doneAnyway"
                              @next="nextQuestion"
                              @previous="previousQuestion"
                            />
                          </vs-col>
                        </vs-row>

                        <div v-if="started && showSingleQuestion" class="flex w-full pb-4 pt-4">
                          <div class="flex flex-col items-center justify-center w-full">
                            <question-pagination
                              :reduced="isSmallScreen"
                              :disable-next="disableNext"
                              :disable-previous="disablePrevious"
                              :pagination-info="paginationInfo"
                              :show-done="doneAnyway"
                              @next="nextQuestion"
                              @previous="previousQuestion"
                            />
                            <div :class="[showQuestionTimer ? 'flex' : 'hidden' ,'pt-2 gap-1 items-start justify-center font-bold']" style="font-size: 10px;">
                              <label>{{ $t('tempo-questao') }}</label>
                                <simple-timer
                                  ref="simpleTimer"
                                  @ratio="checkQuestionTimeRatio"
                                  @zero="currentQuestionTimerZeroed"
                                  :value="questionTimer"
                                  v-model="questionTimer"
                                  auto-start
                                />
                            </div>
                          </div>
                        </div>

                        <!-- Pause button -->
                        <div>
                          <vs-button
                            v-if="questionnaireCanPause && started"
                            class="bg-white w-full mt-4"
                            icon="pan_tool"
                            type="border"
                            @click="showPauseForm = true"
                          > {{ $t('solicitar-pausa') }} </vs-button>
                        </div>

                      </div>

                    </vx-card>

                    <vs-card
                      collapseAction
                      v-if="supportInProgress || userMediaStream !== null"
                      :isContentCollapsedProp="monitorCardCollapsed"
                      class="mt-4"
                    >

                      <div slot="header" class="font-semibold">
                        {{ $t('monitoramento') }}
                      </div>

                      <div class="grid justify-items-center items-center">

                        <div>
                          <video
                            v-if="userMediaStream !== null"
                            id="video"
                            autoplay="autoplay"
                            muted
                            disablePictureInPicture
                            class="video-player transform">
                          </video>
                        </div>

                        <div class="flex justify-items-center items-center">

                          <div class="open-call-support flex items-center" v-if="showSupportPhoneCall && isProctoringLiveChat" :key="supportKey">
                              <font-awesome-icon
                                  @click="openCallSupport()"
                                  icon="phone"
                                  :class="['cursor-pointer', waitingSupport ? 'bell' : '']"
                                  style="font-size: 18px"
                              />
                              <div class="pl-2">
                                {{ waitingSupport ? 'Aguardando monitor...' : 'Ligar para monitor'}}
                              </div>
                          </div>
                          <div class="close-call-support flex items-center" v-if="isProctoringLiveChat && supportInProgress">
                              <font-awesome-icon
                                  @click="closeCallSupport()"
                                  icon="phone"
                                  class="cursor-pointer"
                                  style="font-size: 18px"
                              />
                              <div class="pl-2">{{ $t('desligar-chamada') }}</div>
                          </div>
                        </div>

                        <vs-divider v-if="supportInProgress"/>

                        <!-- Monitor/Supervisor video player -->
                        <div v-if="supportInProgress">
                          <b>Monitor:</b> {{supportInProgress.monitorName}}
                        </div>
                        <div v-if="supportInProgress" ref="monitoVideoPlayerDiv" class="video-player"></div>

                      </div>

                    </vs-card>

                  </div>


                </div>

            </vs-card>
            </div>

          </div>
        </vs-sidebar>

        <div style="width: 100%; position: absolute; bottom: 10px;">
          <div v-if="started" class="w-full items-center justify-items-center">
            <div class="w-full flex items-center justify-center gap-2 mt-2">
              <!-- <img src="@/assets/images/logo/logo.png" alt="$t('educatena-0')" style="max-width: 100px;"/> -->
              <!-- <img
                class="border-faint-grey border-solid border-l pl-2 border-t-0 border-b-0 border-r-0"
                v-if="organizationLogo"
                :src="organizationLogo"
                :alt="$t('oganization-and-and-organization-name-or-or')"
                style="max-width: 100px;"
              /> -->
              <logo public style="max-width: 100px;" :institutionLogo="organizationLogo"/>
            </div>
          </div>
        </div>

      </div>

      <div v-if="started && hasTemplate" class="min-h-screen">

            <iframe ref="templateRef" :src="questionnaireTemplatePath" frameborder="0" class="iframe-template" height="100%" width="100%"></iframe>

      </div>

      <vs-popup
        style="z-index: 20000000;"
        :title="$t('habilitar-monitoramento-proctoring')"
        :active.sync="showProctoringInit"
        fullscreen="true"
        :button-close-hidden="true"
      >
        <div class="h-full proctoring-image-guide">

          <!-- Browser compability -->
          <div v-if="!isValidBrowser">

            <div class="grid grid-flow-row auto-rows-max">

              <div>

                <div class="vx-row pb-4">
                  <div class="vx-col w-full flex items-center justify-center">
                    <h4>
                      {{ $t('o-seu-navegador-capitalize-browsername-versao-browserversion-nao-e-compativel', [capitalize(browserName()), browserVersion()]) }}
                    </h4>
                  </div>
                </div>

                <div class="vx-row">
                  <div class="vx-col w-full flex items-center justify-center">
                    <h6>
                      {{ $t('favor-atualizar-para-a-versao-mais-recente-ou-utilizar-um-dos-navegadores-abaixo-em-suas-respectivas-versoes') }} </h6>
                  </div>
                </div>

              </div>

              <div class="m-4 pt-6">
                <div class="grid grid-cols-1 md:grid-cols-3">

                  <div>
                    <div class="w-full flex items-center justify-center">
                        <img src="@/assets/images/pages/proctoring/firefox-logo.png" style="width: 100px"/>
                      </div>
                      <span class="flex items-center justify-center mt-2">{{ $t('firefox-versao-66-ou-superior') }}</span>
                  </div>

                  <div>
                    <div class="w-full flex items-center justify-center">
                      <img src="@/assets/images/pages/proctoring/chrome-logo.png" style="width: 100px"/>
                    </div>
                    <span class="flex items-center justify-center mt-2">{{ $t('chrome-versao-72-ou-superior') }}</span>
                  </div>

                  <div>
                    <div class="w-full flex items-center justify-center">
                      <img src="@/assets/images/pages/proctoring/opera-logo.png" style="width: 100px"/>
                    </div>
                    <span class="flex items-center justify-center mt-2">{{ $t('opera-versao-60-ou-superior') }}</span>
                  </div>

                </div>

              </div>

              <vs-divider/>

              <div class="w-full flex items-center justify-center">
                <vs-button
                  @click="showProctoringInit = false">
                  {{ $t('ok') }}
                </vs-button>
              </div>

            </div>

          </div>

          <!-- Step 1 - Screenshare without error -->
          <div class="w-full flex" v-if="isValidBrowser && !screenShareEnabled && !userMediaEnabled && !captureScreenshareError">

            <div class="w-full">
              <div class="flex mb-4">

                <div class="w-full">

                  <vs-alert
                    active="true"
                    icon="new_releases"
                    class="mb-4 h-auto">
                    {{ $t('este-questionario-sera-monitorado-utilizando') }} <b>{{ $t('proctoring.by_remote_monitoring') }}</b> {{ $t('e-os-dados-da-sua-tela-camera-e-microfone-serao-capturados-e-enviados-para-posterior-analise-de-autenticidade') }} </vs-alert>
                  <vs-alert
                    active="true"
                    icon="new_releases"
                    class="mb-4 h-auto">
                    {{ $t('nenhuma-imagem-sera-compartilhada-com-terceiros-e-somente-serao-utilizadas-para-comprovacao-da-autenticidade-das-informacoes-fornecidas') }}
                  </vs-alert>

                  <vs-alert
                    active="true"
                    icon="new_releases"
                    class="mb-4 h-auto">
                    {{ $t('as-imagens-somente-ficarao-armazenadas-no-sistema-por-um-prazo-maximo-de-14-dias-sendo-totalmente-apagadas-apos-este-periodo') }}
                  </vs-alert>

                  <vs-alert
                    active="true"
                    icon="new_releases"
                    class="mb-4 h-auto">
                    {{ $t('apos-selecionar-o-botao-habilitar-sera-apresentada-uma-janela-de-confirmacao-semelhante-a-imagem-abaixo') }}
                  </vs-alert>

                  <vs-alert
                    active="true"
                    icon="new_releases"
                    class="mb-4 h-auto">
                    {{ $t('escolha-a-opcao-a-tela-inteira-e-selecione-o-botao-compartilhar') }}
                  </vs-alert>

                  <vs-alert
                    active="true"
                    icon="new_releases"
                    class="mb-4 h-auto">
                    {{ $t('agora-voce-iniciara-sua-prova') }}
                  </vs-alert>
                </div>
              </div>

              <div class="grid grid-cols-1 sm:grid-cols-2">

                <div class="flex items-center justify-center">
                  <img
                    :src="screenShareImage"
                    style="max-width: 90%; max-height: 300px;"/>
                </div>

                <div class="flex items-center justify-center mt-4">
                    <vs-button
                      class="mr-2"
                      @click="enableScreenShare"
                      icon="icon icon-monitor"
                      icon-pack="feather"
                    >
                      {{ $t('habilitar') }}
                    </vs-button>
                    <vs-button type="border" color="danger" @click="cancelProctoringInit">{{ $t('action.cancel') }}</vs-button>
                </div>

              </div>

            </div>

          </div>

          <!-- Step 2 - Screenshare with error -->
          <div v-if="isValidBrowser && !screenShareEnabled && !userMediaEnabled && captureScreenshareError" class="h-full">

            <vs-row class="mb-2">
              <vs-col
                vs-type="flex"
                vs-justify="start"
                vs-align="start"
                vs-w="12">
                <div>
                  <ul class="pl-4" style="list-style-type: disc">

                    <vs-alert
                      active="true"
                      icon="new_releases"
                      class="mb-4 h-auto">
                      {{ $t('houve-um-erro-para-iniciar-a-captura-de-tela-favor-verificar-se-nao-ha-bloqueio-de-permissao-de-acordo-com-a-imagem-abaixo') }}
                    </vs-alert>

                    <vs-alert
                      active="true"
                      icon="new_releases"
                      class="mb-4 h-auto">
                      {{ $t('caso-haja-bloqueio-para-o-compartilhamento-remova-o-bloqueio-e-tente-novamente') }}
                    </vs-alert>

                    <div class="mt-2 mb-4" style="color: red;" v-html="captureScreenshareError"></div>

                  </ul>
                </div>
              </vs-col>
            </vs-row>

            <vs-row class="margin-bottom: 25px;">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12">
                <img
                  :src="screenShareErrorImage"
                  style="max-width:100%;"/>
              </vs-col>
            </vs-row>

            <vs-divider/>

            <vs-row>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12">

                <div class="flex items-center justify-center gap-4">
                  <div>
                    <vs-button
                      class="mr-2"
                      @click="enableScreenShare"
                      icon="icon icon-monitor"
                      icon-pack="feather"
                    >
                      {{ $t('habilitar') }}
                    </vs-button>
                  </div>
                  <div>
                    <vs-button type="border" color="danger" @click="cancelProctoringInit">{{ $t('action.cancel') }}</vs-button>
                  </div>
                </div>

              </vs-col>
            </vs-row>

          </div>

          <!-- Step 3 - media without error -->
          <div v-if="isValidBrowser && screenShareEnabled && !userMediaEnabled && !captureUserMediaError" class="h-full">

            <div class="w-full">
              <div class="flex mb-4">

                <div class="w-full">

                  <vs-alert
                    active="true"
                    icon="new_releases"
                    class="mb-4 h-auto">
                    {{ $t('nesta-etapa-sera-necessario-habilitar-a-captura-de-video-da-camera-e-o-audio-do-microfone') }}
                  </vs-alert>

                  <vs-alert
                    active="true"
                    icon="new_releases"
                    class="mb-4 h-auto">
                    {{ $t('apos-selecionar-o-botao-habilitar-sera-apresentada-uma-janela-de-confirmacao-semelhante-a-imagem-abaixo') }}
                  </vs-alert>

                  <vs-alert
                    active="true"
                    icon="new_releases"
                    class="mb-4 h-auto">
                    {{ $t('e-muito-importante-que-habilite-a-camera-frontal-e-um-microfone-que-esteja-em-funcionamento-sera-necessario-usar-estes-dispositivos-posteriormente-para-validar-seu-acesso') }}
                  </vs-alert>

                  <vs-alert
                    active="true"
                    icon="new_releases"
                    class="mb-4 h-auto">
                    {{ $t('caso-nao-apareca-uma-tela-para-habilitar-a-captura-semelhante-a-imagem-abaixo-possivelmente-o-compartilhamento-esteja-bloqueado-selecione-a-opcao-de-compartilhamento-com-base-na-imagem-abaixo-localizado-proximo-a-barra-de-enderecos-e-habilite-a-opcao-para-compartilhar-a-camera-e-microfone') }}
                  </vs-alert>

                </div>
              </div>

              <div class="grid grid-cols-1 sm:grid-cols-2">

                <div class="flex items-center justify-center">
                  <img
                    :src="userMediaImage"
                    style="max-width:100%;"/>
                </div>

                <div class="flex items-center justify-center mt-4">

                  <div>
                    <vs-button
                      class="mr-2"
                      @click="enableUserMedia"
                      icon="icon icon-monitor"
                      icon-pack="feather"
                    >
                      {{ $t('habilitar') }}
                    </vs-button>
                  </div>
                  <div>
                    <vs-button type="border" color="danger" @click="cancelProctoringInit">{{ $t('action.cancel') }}</vs-button>
                  </div>

                </div>

              </div>

            </div>

          </div>

          <!-- Step 4 - media with error -->
          <div v-if="isValidBrowser && screenShareEnabled && !userMediaEnabled && captureUserMediaError" class="h-full">

            <vs-row class="mb-2">
              <vs-col
                vs-type="flex"
                vs-justify="start"
                vs-align="start"
                vs-w="12">
              </vs-col>

              <ul class="pl-4" style="list-style-type: disc">

                <li class="mb-2">
                  {{ $t('nao-foi-possivel-habilitar-a-camera-e-ou-microfone-por-favor-verifique-se-estes-dispositivos-estao-funcionando-corretamente-e-as-permissoes-estao-liberadas') }} </li>

                <li class="mb-2" v-if="isMacOS">
                  {{ $t('verifique-se-o-seu-navegador-possui-as-permissoes-necessarias-em') }}
                  <b><a href="x-apple.systempreferences:com.apple.preference.security?Privacy_Camera">{{ $t('preferencias-greater-than-seguranca-e-privacidade-greater-than-privacidade') }}</a></b>{{ $t('marque-o-seu-navegador-em') }} <b>{{ $t('camera') }}</b> e <b>{{ $t('microfone') }}</b>{{ $t('de-acordo-com-a-imagem-abaixo') }} </li>

              </ul>

            </vs-row>

            <vs-row class="margin-bottom: 25px;">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12">

                  <div class="flex items-center justify-center">
                    <img
                        class="mr-2"
                        :src="userMediaErrorImage"
                        style="max-width:30%;"/>
                    <img
                        v-if="isMacOS"
                        class="mr-2"
                        src="@/assets/images/pages/proctoring/maccatalina_permission_cam.jpeg"
                        style="max-width:30%;"/>
                      <img
                        v-if="isMacOS"
                        src="@/assets/images/pages/proctoring/maccatalina_permission_mic.jpeg"
                        style="max-width:30%;"/>
                  </div>

              </vs-col>
            </vs-row>

            <vs-row style="bottom: 25px; margin-left:-25px; position: fixed !important;">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12">
              </vs-col>

              <div class="flex items-center justify-center mt-4 w-full">
                <div class="grid grid-cols-2 gap-4">
                  <div>
                    <vs-button
                      class="mr-2"
                      @click="enableUserMedia"
                      icon="icon icon-monitor"
                      icon-pack="feather"
                    >
                      {{ $t('habilitar') }}
                    </vs-button>
                  </div>
                  <div>
                    <vs-button type="border" color="danger" @click="cancelProctoringInit">{{ $t('action.cancel') }}</vs-button>
                  </div>
                </div>
              </div>

            </vs-row>
          </div>

          <!-- Step 5 - Final step -->
          <div v-if="isValidBrowser && screenShareEnabled && userMediaEnabled" class="h-full">

            <div class="grid grid-cols-1 sm:grid-cols-2 h-full">

              <div class="h-full" style="border-right: 1px solid #dadce0;">

                <div class="flex items-center justify-center mt-4">
                    <h3>{{ $t('tire-uma-foto-documento') }}</h3>
                </div>

                <div class="flex items-center justify-center mt-4">
                    <video
                      v-show="userMediaStream !== null && this.showProctoringInit && userPicture === null"
                      id="video-document"
                      muted
                      disablePictureInPicture
                      class="video-player-document transform w-full">
                    </video>
                    <canvas id="picture" style="display:none;"></canvas>
                    <img v-show="userPicture !== null" id="picture_img" class="transform w-full sm:w-1/2">
                </div>

                <div v-if="getDeviceVideoError !== null" class="flex items-center justify-center mt-4">
                  <img src="@/assets/images/no_image_placeholder.png" alt="$t('no-camera')" style="max-width: 300px;"/>
                </div>

                <div class="flex items-center justify-center mt-4">
                    <vs-button
                      v-if="userPicture === null && getDeviceVideoError === null"
                      class="mr-2"
                      @click="takePicture"
                      icon="icon icon-camera"
                      icon-pack="feather"
                    >
                        {{ $t('capturar-foto') }}
                    </vs-button>

                    <vs-button
                      v-if="userPicture !== null"
                      type="border"
                      color="danger"
                      class="mr-2"
                      @click="userPicture = null"
                      icon="icon icon-x-circle"
                      icon-pack="feather"
                    >
                      {{ $t('tentar-novamente') }}
                  </vs-button>
                </div>

                <div class="flex items-center justify-center mt-4">
                  <vs-divider>{{ $t('validacao-de-microfone') }}</vs-divider>
                </div>

                <div class="grid grid-rows-2 mt-4">

                  <div class="flex items-center justify-center">
                      <vs-progress :percent="micVolume" color="success">success</vs-progress>
                  </div>

                  <div class="flex items-center justify-center">
                      <span class="mic-config mt-2 text-center" v-if="!micSuccess">{{ $t('fale-algo-em-voz-alta-para-validar-a-configuracao-do-seu-microfone') }}</span>
                      <span class="mic-succes mt-2" v-if="micSuccess">{{`${$t('sucesso')}!`}}</span>
                  </div>
                </div>

              </div>

              <div class="grid grid-rows-6 p-4">

                <div class="row-span-2">
                  <div v-if="videoDevices.length > 0">
                    <vs-select
                      class="w-full"
                      :label="$t('entradas-de-video-camera')"
                      v-model="selectedVideoDeviceId"
                      @input="enableUserMedia"
                    >
                        <vs-select-item :key="index" :value="device.deviceId" :text="device.label" v-for="(device,index) in videoDevices" />
                    </vs-select>

                    <vs-alert
                      v-if="getDeviceVideoError !== null"
                      active="true"
                      color="danger"
                      icon="new_releases"
                      class="mt-2 h-auto">
                      <div v-html="getDeviceVideoError"></div>
                    </vs-alert>

                  </div>

                  <div v-if="audioDevices && audioDevices.length > 0" class="mt-4">
                    <vs-select
                      class="w-full"
                      :label="$t('entradas-de-audio-microfone')"
                      v-model="selectedAudioDeviceId"
                      @input="enableUserMedia"
                    >
                      <vs-select-item :key="index" :value="device.deviceId" :text="device.label" v-for="(device,index) in audioDevices" />
                    </vs-select>

                    <vs-alert
                      v-if="getDeviceAudioError !== null"
                      active="true"
                      color="danger"
                      icon="new_releases"
                      class="mt-2 h-auto">
                      <div v-html="getDeviceAudioError"></div>
                    </vs-alert>

                  </div>
                </div>

                <div class="row-span-4">

                  <vs-divider/>

                  <vs-alert
                    active="true"
                    icon="new_releases"
                    class="mb-4 text-warning h-auto">
                    {{ $t('nao-e-permitido-o-uso-de-fones-de-ouvido-ou-similares') }}
                  </vs-alert>

                  <vs-alert
                    active="true"
                    icon="new_releases"
                    class="mb-4 text-warning h-auto">
                    {{ $t('nao-mantenha-conversa-com-pessoas-ao-redor') }}
                  </vs-alert>

                  <vs-alert
                    active="true"
                    icon="new_releases"
                    class="mb-4 h-auto">
                    {{ $t('desejamos-uma-otima-avaliacao') }}
                  </vs-alert>

                  <vs-divider/>

                  <div class="flex items-center justify-center gap-4 mt-4">
                    <div>
                      <vs-button
                          class="mr-2 start-questionnaire"
                          @click="startQuestionnaire"
                          icon="play_arrow"
                          :disabled="userPicture === null || !micSuccess"
                      >
                          {{ $t('news_start_at') }}
                      </vs-button>
                    </div>
                    <div>
                      <vs-button type="border" color="danger" @click="cancelProctoringInit">{{ $t('action.cancel') }}</vs-button>
                    </div>
                  </div>

                </div>
              </div>

            </div>

          </div>

        </div>

      </vs-popup>

      <!-- Fullscreen alert dialog -->
      <vs-prompt
          color="warning"
          :title="$t('atencao-0')"
          type="confirm"
          cancel-text = "Encerrar avaliação"
          accept-text= "Voltar para tela cheia"
          @accept="requestFullscreen"
          @cancel="finishInterval"
          @close="fullscreenActivePrompt = true"
          :active.sync="fullscreenActivePrompt">

          <span v-html="forceFullscreenMessage"></span>
      </vs-prompt>

    </questionnaire-event-overseer>

    <vs-popup
    class="_form-answer __navigation-warning-popup"
    :title="$t('atencao-0')"
    :active="showQuestionNavigationWarning"
    :buttonCloseHidden="true"
    >
      <span class="text-lg font-medium"> {{ $t('este-questionario-nao-permite-voltar-para-a-questao-tem-certeza-que-deseja-avancar') }} </span>
      <div :class="['flex', 'pt-6', warnedDisallowReturn ? 'text-primary' : 'text-danger']">
        <vs-checkbox v-model="warnedDisallowReturn"/>
        <label>{{ $t('entendi-nao-mostrar-novamente') }} </label>
      </div>
      <vs-divider class="w-full"/>
      <div class="flex gap-2 w-full justify-end">
        <vs-button @click="navigationWarningProcceed" color="primary">{{ $t('avancar') }}</vs-button>
        <vs-button @click="navigationWarningCancel" color="danger" type="border">{{ $t('action.cancel') }}</vs-button>
      </div>
    </vs-popup>
    <!-- Entrance delay justification form popup -->
    <vs-popup
      :title="$t('justificar-atraso')"
      :active="promptEntranceJustification"
      :button-close-hidden="true"
    >
      <justification-form
        @save="emitEntranceJustificationEvent"
        :showCancelButton="false"
        :alerts="[
          {
            text: 'Você está atrasado para iniciar o questionário, justifique o motivo.',
            color: 'rgb(231, 154, 23)',
            title: 'Alerta',
            classes: 'text-warning h-full'
          }
        ]"
      />
    </vs-popup>

    <!-- Pause Overlay -->
    <pause-overlay @leaveQuestionnairePause="leavePause" v-if="showPausedOverlay" />

    <!-- Questionnaire Pause justification form popup -->
    <vs-popup :title="$t('solicitar-pausa')" :active.sync="showPauseForm">
      <justification-form
        @cancel="showPauseForm = false"
        @save="emitPauseEvent"
        :alerts="[
          {
            text: 'Ao iniciar uma pausa justificada, se o questionário for com tempo, o período/tempo da sua avaliação não é alterado.',
            color: 'rgb(231, 154, 23)',
            title: 'Alerta',
            classes: 'text-warning h-full'
          }
        ]"
      />
    </vs-popup>

    <vs-popup :title="$t('voicecontrolactivated-instrucoes-ativacao-controle-de-voz') " :active.sync="showVoiceControlConfirmation">
        <div class="text-lg font-medium" v-if="!voiceControlActivated"> {{ $t('deseja-ativar-o-controle-por-voz') }} </div>
        <div class="text-lg font-normal mt-2 mb-4">
          {{ $t('usando-comandos-de-voz-e-possivel-realizar-todo-o-processo-com-instrucoes-usando-a-voz-leia-os-comandos-abaixo-ou-diga-ler-instrucoes-apos-ativar-para-que-o-sistema-leia-as-instrucoes') }}
        </div>

        <div class="flex gap-2 w-full justify-end" v-if="!voiceControlActivated">
          <vs-button @click="voiceControlStateChange(true)" color="primary">{{ $t('sim-ativar') }}</vs-button>
          <vs-button @click="voiceControlStateChange(false)" color="danger" type="border">{{ $t('nao') }}</vs-button>
        </div>

        <vs-divider class="w-full"> {{ $t('instrucoes') }} </vs-divider>

        <vs-table :data="voiceCommands">
          <template slot="thead">
            <vs-th width="35%">{{ $t('comando') }}</vs-th>
            <vs-th width="15%">{{ $t('description') }}</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="i" v-for="(tr, i) in data" >
              <vs-td>{{ data[i].value }}</vs-td>
              <vs-td>{{ data[i].description }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>

    </vs-popup>

    <vx-tour
      v-show="activeSidebar"
      name="questionnaireAnswerSidebar"
      stop-text="OK"
      :auto="true"
    />

  </div>
</template>

<script>
import Vue from 'vue'
import SectionAnswer from './questions/SectionAnswer'
import SimpleTextAnswer from './questions/SimpleTextAnswer'
import MultipleChoiceAnswer from './questions/MultipleChoiceAnswer'
import CheckBoxAnswer from './questions/CheckBoxAnswer'
import GapAnswer from './questions/GapAnswer'
import EssayAnswer from './questions/EssayAnswer'
import CountDown from './CountDown'
import {getQuestionSection} from '@/util/QuestionnaireUtil'
import { PROCTORING_SOCKET_TYPE, PROCTORING_STREAM_TYPE, QUESTION_STATUS } from '@/util/Enums'
import ContentQuestionnaireService from '@/services/api/ContentQuestionnaireService'
import QuestionnaireAnswerService from '@/services/api/QuestionnaireAnswerService'
import screenfull from 'screenfull'
import AwsService from '@/services/api/AwsService'
import MediaService from '@/services/api/MediaService'
import StreamQueue from '../../../../models/StreamQueue'
import StreamRecorder from '../../../../util/StreamRecorder'
import Peer from 'peerjs'
// import Peer from 'peerjs/dist/peerjs.js'
// import {Peer} from 'peerjs'
import OrganizationService from '@/services/api/OrganizationService'
import SimpleTimer from '@/components/SimpleTimer.vue'
import { isArray, isString } from 'lodash'
import QuestionnaireEventOverseer from './QuestionnaireEventOverseer.vue'
import { EnterQuestionnaireLink, ConnectionRateEvent, EnterQuestionnairePauseEvent, LeaveQuestionnairePauseEvent, QuestionnaireJustifyEntranceEvent, StartProctoringRecordingEvent } from '@/models/events/DOMEvents'
import PauseOverlay from './PauseOverlay.vue'
import JustificationForm from './JustificationForm.vue'
import GeneralInformation from './GeneralInformation.vue'
import { NotificationActionCodes } from '@/util/Exceptions'
import QuestionPagination from './QuestionPagination.vue'
import { getTextReadableTime } from '@/util/Util'
import { MOMENT_THRESHOLDS } from '@/util/Constants'
const VxTour = () => import('@/components/VxTour.vue')
import SpeechRecognitionProvider from '../../../../util/SpeechRecognitionProvider'
import Logo from '@/layouts/components/Logo.vue'
import DocumentPersonOnBoard from '@/views/apps/document/DocumentPersonOnBoard'

export default {
  components: {
    SectionAnswer,
    SimpleTextAnswer,
    MultipleChoiceAnswer,
    CheckBoxAnswer,
    GapAnswer,
    EssayAnswer,
    CountDown,
    SimpleTimer,
    QuestionnaireEventOverseer,
    PauseOverlay,
    JustificationForm,
    GeneralInformation,
    VxTour,
    QuestionPagination,
    Logo,
    DocumentPersonOnBoard
  },
  props: {
    short_url_id: {
      type: String,
      default: null
    },
    lead_id: {
      type: Number,
      default: null
    },
    preview: {
      type: Boolean,
      default: false
    },
    pratical_test: {
      type: Object,
      default: () => {
        return {
          practical_test_student_id: null,
          section_id: null,
          user_id: null
        }
      }
    },
    practical_test_student_id: {
      type: Number,
      default: null
    },
    section_id: {
      type: String,
      default: null
    },
    user_id: {
      type: String,
      default: null
    },
  },
  data: () => ({
    eventBus: new Vue(),
    // If the 20% question timer warning has been shown
    questionTimer20Warned: false,
    questionTimer10Warned: false,
    questionnaireTimerWarned: false,
    isSmallScreen: false,
    questionStates: [],
    durationShortage: false,
    // bookmarks: {},
    panelQuestionKey: 0,
    service: null,
    questionnaireAnswerService: null,
    organizationService: null,
    questionnaire: null,
    questionnaireAnswer: {
      now: null
    },
    progressiveQuestionnaireCount: 0,
    presentedField: null,
    presentedFieldIndex: 0,
    currentQuestionDisabled: false,
    presentedSectionIndex: 0,
    presentedSectionPosition: 0,
    showRequiredQuestions: [],
    multipleAttempts: false,
    started: false,
    continue: false,
    password: '',
    showPassword: false,
    generalTimeZero: false,
    questionTimer: null,
    questionTimers: null,
    questionTimerEnabled: false,
    key: 0,
    showStart: false,
    startError: null,
    startErrorCode: null,
    user: null,
    description: '',
    streamRecorder: null,
    awsService: null,
    showProctoringInit: false,
    screenShareStream: null,
    userMediaStream: null,
    screenShareEnabled: false,
    mergedMediaStream: null,
    userMediaEnabled: false,
    captureUserMediaError: false,
    captureScreenshareError: null,
    showVideoPlayer: false,
    presentVideoPlayer: false,
    isValidBrowser: false,
    camPeer: null,
    monitorVideoPlayer: null,
    monitorRemoteStream: null,
    showMonitorVideoPlayer: false,
    userPicture: null,
    micVolume: 0,
    micSuccess: false,
    organization: null,
    organizationLogo: null,
    answersQueue: [],
    finishIntervalRef: null,
    connectedPeer: null,
    fullscreenActivePrompt: false,
    autoFinishInterval: null,
    devToolsOpened: false,
    callSupportOpened: false,
    supportConnection: null,
    supportInProgress: null,
    waitingSupport: false,
    questionTimerZeroed: false,
    micSoundInterval: false,
    supportKey: 0,
    eventQueueSize: 0,
    sentConnectionEvent: false,
    connectionSpeedInterval: null,
    audioDevices: [],
    selectedAudioDeviceId: null,
    videoDevices: [],
    selectedVideoDeviceId: null,
    paused: false,
    promptEntranceJustification: false,
    showPauseForm: false,
    finishInProgress: false,
    getDeviceVideoError: null,
    getDeviceAudioError: null,
    questionCardCollapsed: false,
    countdownCardCollapsed: false,
    monitorCardCollapsed: false,
    // Whether we have warned the user about not being able to return. EDU-1494
    warnedDisallowReturn: false,
    showQuestionNavigationWarning: false,
    navigationHold: null,
    activeSidebar: true,
    scrolled: 0,
    screenfullInstance: null,
    voiceControlActivated: false,
    showVoiceControlConfirmation: false,
    speechRecognitionProvider: new SpeechRecognitionProvider(),

    // Util Functions
    runDebounced: null,
  }),
  computed: {
    cannotGoBack() {
      if (this.preview) return false
      else return this._.get(this.questionnaire, 'disallow_question_return', false) || false
    },
    sections() {
      const fields = this.questions
      let sectionAccumulator = []
      const sections = []

      const endSection = () => {
        sections.push(sectionAccumulator)
        sectionAccumulator = []
      }

      fields.forEach((question, position) => {
        const type = this._.get(question, 'type', null) || null

        if (type === 'section' && position !== 0) {
          /**
           * We only end sections on questions of type section,
           * never on the first question.
           */
          endSection()
        }

        sectionAccumulator.push(question)

        if (position === fields.length - 1) {
          /**
           * If we are at the last question and there was no section
           * to finish the currently accumulating section,
           * we end said section.
           */
          endSection()
        }
      })

      return sections
    },
    bookmarks: {
      get: function() {
        try {
          if (!this.questionnaireAnswer) return []
          const answerData = this._.get(this.questionnaireAnswer, 'data')
          if (!answerData) return []
          const answerFields = JSON.parse(answerData)
          if (this._.isArray(answerFields) && answerFields.length > 0) {
            const bookmarks = Object.fromEntries(
              answerFields.map(
                (field) => {
                  const id = this._.get(field, 'id')
                  const bookmarked = this._.get(field, 'bookmarked', false)

                  this.$nextTick(() => {
                    this.$root.$emit('updateRevisionMark', id, bookmarked)
                  })

                  return [id, bookmarked]
                }
              )
            )

            return bookmarks
          } else return []
        } catch (error) {
          console.error('I\'m sorry dear, it was not possible to load the question bookmarks')
          console.error(error)
          return []
        }
      },
      set: function (val) {
        const id = this._.get(val, 'id', false)
        const value = this._.get(val, 'value', false)
        if (id !== false) {
          try {
            const answerFields = JSON.parse(this._.get(this.questionnaireAnswer, 'data'))
            if (this._.isArray(answerFields) && answerFields.length > 0) {
              const fieldIndex = answerFields.findIndex((field) => field.id === id)

              if (fieldIndex !== -1) {
                answerFields[fieldIndex].bookmarked = value
                this._.set(this.questionnaireAnswer, 'data', JSON.stringify(answerFields))
              } else {
                console.error('Could not update a bookmark... didnt find the field.')
              }
            }
          } catch (error) {
            console.error('Could not update a bookmark...')
            console.error(error)
          }
        }
      }
    },
    showStartButton() {
      if (this.preview) return true

      return this.showStart && !this.started && !this.startError && this.withinPeriod
    },
    disableStartButton() {
      if (this.preview) return false

      return !this.startEnabled || this.startError
    },
    isRecordingEvents() {
      return !this.preview
    },
    questionnaireCanPause() {
      const canPause = this._.get(this.questionnaire, 'can_pause', false)
      return (canPause && !this.preview)
    },
    entranceJustificationTime() {
      const justificationTime = this._.get(this.questionnaire, 'entrance_justification_time', null)
      return justificationTime
    },
    progressiveQuestionnaire() {
      return this._.get(this.questionnaire, 'progressive_questionnaire', false)
    },
    showPausedOverlay() {
      return this.isPaused && this.started
    },
    isPaused() {
      return this.paused
    },
    answerId() {
      return this._.get(this.questionnaireAnswer, 'id', null)
    },
    answerQuestions() {
      const questions = this._.get(this.questionnaireAnswer, 'questions', []) || []
      if (questions && this._.isArray(questions) && questions.length > 0) {
        return questions
      } else {
        return []
      }
    },
    questions() {
      return this.answerQuestions
    },
    questionnaireName() {
      return this.questionnaire && this.questionnaire.name
        ? this.questionnaire.name
        : ''
    },
    userName() {
      return (this.$store.state.AppActiveUser && this.$store.state.AppActiveUser.displayName)
        ? this.$store.state.AppActiveUser.displayName
        : null
    },
    userEmail() {
      return (this.$store.state.AppActiveUser && this.$store.state.AppActiveUser.email)
        ? this.$store.state.AppActiveUser.email
        : null
    },
    hideQuestionScore() {
      return this.questionnaire && this.questionnaire.hide_question_score
    },
    showQuestionTimer() {
      return this.questionTimerEnabled
    },
    hasAnyQuestionTimer() {
      return (this.questionTimers && true) || false
    },
    disablePrevious() {
      return (
        !this.questionnaire.single_section
          ? this.presentedFieldIndex === 0
          : this.presentedSectionIndex === 0
      ) || this.cannotGoBack
        || this.progressiveQuestionnaire
    },
    /** Next is disabled if the queue should be waited upon,
     *  or if the user is at the last question/section already.
     */
    disableNext() {
      /** Safer singleQuestion verification */
      const singleQuestion = (this.questionnaire && this.questionnaire.single_question) || false

      return  (
        this.waitQueue || (
          singleQuestion
            ? this.presentedFieldIndex >= this.dataFields().length
            : this.presentedSectionIndex >= this.sections.length
        )
      )
    },
    showDone() {
      if (this.progressiveQuestionnaire) {
        return (
          this.presentedFieldIndex >= this.progressiveQuestionnaireCount - 1 || this.showFullQuestionnaire
        )
      }

      return (
        this.presentedFieldIndex >= this.dataFields().length - 1 || this.showFullQuestionnaire
      )
    },
    showSectionDone() {
      if (this.showSingleQuestion) return false
      return (
        this.presentedSectionIndex + 1 >= this.sections.length ||
        !(this.showSingleQuestion || this.showSingleSection)
      )
    },
    startEnabled() {
      return !this.showPassword || (this.showPassword && this.password !== '')
    },
    showSingleSection() {
      return this.questionnaire.single_section
    },
    showSingleQuestion() {
      return this.questionnaire.single_question
    },
    showCanPause() {
      return false
    },
    showIsPaused() {
      return false
    },
    showHasEntranceTime() {
      return false
    },

    withinPeriod() {
      if (this.preview) {
        return true
      }

      const start = this.$moment(this.questionnaire.start_at)
      const finish = this.$moment(this.questionnaire.finish_at)
      const now = this.$moment()
      if (start.isValid() && finish.isValid()) {
        // Both finish and start dates are valid.
        return now.isBetween(start, finish)

      } else if (start.isValid() && !finish.isValid()) {
        // The teacher only set a finish period date.
        return now.isAfter(start)

      } else if (!start.isValid() && finish.isValid()) {
        // The teacher only set a start period date
        return now.isBefore(finish)

      } else if (!start.isValid() && !finish.isValid()) {
        // The start and finish dates are null or invalid
        return true
      }
      return false
    },
    paginationStyles() {
      let styles =
        this.$t('grid-grid-cols-1-sm-grid-cols-1-md-grid-cols-1-lg-grid-cols-1')
      if (!this.showSingleQuestion && !this.showSingleSection) {
        styles += ' xl:grid-cols-1'
      } else {
        styles += ' xl:grid-cols-2'
      }
      return styles
    },
    questionnaireDuration() {
      return this.questionnaireAnswer.time_remain
    },
    paginationInfo() {
      if (this.showSingleSection) {
        const sections = this.sections[this.presentedSectionIndex]
        const sectionCount =
          sections ? sections.length - 1 : 0
        const questionCount = this.countQuestionsBeforeSection(
          this.presentedSectionIndex
        )

        const numberlessQuestionsInSection = sections ? sections.filter(
          (question) => question.type === 'section'
        ).length : 0

        let questionInterval = 0
        if (sectionCount === 1) {
          questionInterval = questionCount
        } else {
          questionInterval = this.$t('questioncount-a-sectioncount-questioncount-numberlessquestionsinsection', [questionCount, sectionCount + questionCount - numberlessQuestionsInSection])
        }

        return this.$t('questioninterval-de-this-questionsquantity-this-questions', [questionInterval, this.questionsQuantity(this.questions)])
      } else {
        const question = this.questions[this.presentedFieldIndex]
        const questionCount = this.countQuestionsBeforePresentedField(
          this.questions,
          this.presentedFieldIndex
        )
        return question.type !== 'section'
          ? `${questionCount} ${this.$t('de')} ${this.questionsQuantity(this.questions)}`
          : ''
      }
    },
    showFullQuestionnaire() {
      return !this.showSingleQuestion && !this.showSingleSection
    },
    previousQuestionButtonText() {
      return !this.isSmallScreen ? this.$t('common.back') : null
    },
    doneAnyway() {
      return this.showDone || this.showSectionDone
    },
    screenShareImage() {
      try {
        return require(`@/assets/images/pages/proctoring/${this.browserName()}_screenshare.jpeg`)
      } catch (e) {
        return ''
      }
    },
    userMediaImage() {
      try {
        return require(`@/assets/images/pages/proctoring/${this.browserName()}_usermedia.jpeg`)
      } catch (e) {
        return ''
      }
    },
    screenShareErrorImage() {
      try {
        return require(`@/assets/images/pages/proctoring/${this.browserName()}_usermedia_permission.jpeg`)
      } catch (e) {
        return ''
      }
    },
    userMediaErrorImage() {
      try {
        return require(`@/assets/images/pages/proctoring/${this.browserName()}_screenshare_permission.jpeg`)
      } catch (e) {
        return ''
      }
    },
    // Not just returning and also has a duration.
    isReturning() {
      return this.questionnaire.since_creation && this.questionnaire.answer_unfinished
    },
    isProctored() {
      const activeProctoring = this.questionnaire.active_proctoring
      return activeProctoring && activeProctoring.status === 'E'
    },
    isProctoringLive() {
      const medium = this._.get(this.questionnaire, 'active_proctoring.record_medium', false)
      console.log('medium...', medium, medium === PROCTORING_STREAM_TYPE.LiveChat
          || medium === PROCTORING_STREAM_TYPE.LiveChatRecord
            || medium === PROCTORING_STREAM_TYPE.Live)

      // if (medium !== false) {
      //   return medium === PROCTORING_STREAM_TYPE.LiveChat
      //     || medium === PROCTORING_STREAM_TYPE.LiveChatRecord
      //       || medium === PROCTORING_STREAM_TYPE.Live
      // }

      return true
    },
    isProctoringLiveChat() {
      const medium = this._.get(this.questionnaire, 'active_proctoring.record_medium', false)

      if (medium !== false) {
        return medium === PROCTORING_STREAM_TYPE.LiveChat || medium === PROCTORING_STREAM_TYPE.LiveChatRecord
      }

      return false
    },
    isProctoringLiveRecord() {
      const medium = this._.get(this.questionnaire, 'active_proctoring.record_medium', false)
      if (medium !== false) {
        return medium === PROCTORING_STREAM_TYPE.LiveRecord
      }
      return false
    },
    isProctoringRecord() {
      const medium = this._.get(this.questionnaire, 'active_proctoring.record_medium', false)
      if (medium !== false) {
        return medium === PROCTORING_STREAM_TYPE.LiveRecord
            || medium === PROCTORING_STREAM_TYPE.Record
                || medium === PROCTORING_STREAM_TYPE.LiveChatRecord
      }
      return false
    },
    forceFullscreenMessage() {
      let message = ''
      const time = this.forceFullscreenTime()
      switch (time) {
      case 15:
      case 30:
        message = this.$t('sua-avaliacao-sera-encerrada-em-less-than-b-greater-than-time-less-than-b-greater-than-segundos-caso-nao-retorne-ao-modo-de-tela-cheia', [time])
        break
      default:
        message = this.$t('por-favor-retorne-ao-modo-de-tela-cheia')
        break
      }

      return message
    },
    /** Safely gets the queue size, and if the queue is invalid, return 0. */
    queueSize() {
      return (this.answersQueue && this.answersQueue.length) || 0
    },
    /** Determines if the user should wait for the queue to empty before clicking next. */
    waitQueue() {
      const single_question = this.questionnaire && this.questionnaire.single_question
      return (single_question && this.queueSize > 0) || false
    },
    pqUserId() {
      return `pq_user_${this.user_id}_${this.short_url_id}_cam`
    },
    questionnaireDisabled() {
      return this.$store.state.formAnswer.disabled
    },
    showSupportPhoneCall() {
      return this.supportConnection && !this.callSupportOpened && this.presentVideoPlayer
    },
    slowKbpsRate() {
      return this._.get(this.$store.getters, 'slowKbpsRate', 30)
    },
    showOpenDesktopApp() {
      return !this.started
          && this.showStart
            && !this.startError
              && !this.isElectron()
                && this.validateEnvRestrictionDesktop(this.questionnaire.env_restriction)
                  && this.validateEnvRestriction(this.questionnaire.env_restriction)
    },
    sidebarStyle() {
      if (this.showSingleQuestion) {
        return this.$t('height-window-innerheight-100-px-top-12px', [window.innerHeight - 100])
      }
      return this.$t('height-window-innerheight-px-top-12px', [window.innerHeight])
    },
    mobileNavigationPosition() {
      //this.scrolled when 0 it is 3, grows by 0.01 for each x
      let position = 'bottom: 2rem;'

      if (this.scrolled >= 95) {
        //const scale = (this.scrolled * 1).toFixed(2)
        position = 'bottom: 90vh;'
      }

      return position
    },
    // Template
    hasTemplate() {
      return this.questionnaire && this.questionnaire.template
    },
    questionnaireTemplatePath() {
      const path = this.questionnaire && this.questionnaire.template ? this.questionnaire.template.path : ''
      return `${process.env.VUE_APP_QUESTIONNAIRE_TEMPLATE_BASE_URL}/${path}`
    },
    voiceControlEnabled() {
      return this.questionnaire && this.questionnaire.voice_control
    },
    voiceCommands() {
      return this.speechRecognitionProvider ? this.speechRecognitionProvider.getCommands() : []
    },
    isPracticalTest() {
      return !this.isEmpty(this.practical_test_student_id)
    },
    storageAnswerKey() {
      return `stored_answers_${this.questionnaire.id}:${this.answerId}`
    },
    isDocumentPersonOnBoard(){
      if(this.preview){
        return false
      }else{
        return this.startErrorCode && (this.startErrorCode == 900 || this.startErrorCode == 901)
      }
    }
  },
  watch: {
    '$store.getters.windowWidth'(width) {
      this.updateWidth(width)
    },
    presentedField(val) {
      this.disableQuestionTimer()
      this.questionTimer10Warned = false
      this.questionTimer20Warned = false
      this.questionTimerZeroed = false

      /** We just changed the question, if it is not a preview and it has a valid timer
       *  we start it.
       */
      if (!this.preview && this.presentedField && this.presentedField.id) {
        const shouldStartTimer = (
          this.questionTimers && //If there are question timers.
          this.presentedField.id in this.questionTimers && // And a timer for the current question exists
          this.questionTimers[this.presentedField.id] !== null // And its value is not null
        ) || false

        if (shouldStartTimer) {
          this.enableQuestionTimer()
        }
      }

      /**
       * Let's guarantee that the new question is properly enabled,
       * and re-rendered by bumping the render key.
       */
      this.enableCurrentQuestion()
      this.reRenderSingleQuestion()
    },
    questionnaireName() {
      document.title = this.preview ? ('pre-visualizar-this-questionnairename', [this.questionnaireName]) : this.questionnaireName
    },
    started(val) {
      if (val && this.presentedField && this.presentedField.type !== 'section' && this.questionTimers) {
        setTimeout(() => {
          this.enableQuestionTimer()
        }, 50)
      }
    },
    questionTimerEnabled(val) {
      if (val) {
        console.log(val)
      }
    },
    waitQueue(val) {
      const element = this.getButtonNextElement()
      if (val) {
        this.$vs.loading({
          container: element,
          scale: 0.5
        })
      } else {
        this.$vs.loading.close(element)
      }
    },
    '$store.state.formAnswer.disabled'(val) {
      this.reRenderSingleQuestion()
      if (val) {
        this.notifyWarning(
          this.$vs,
          this.$t('o-tempo-para-finalizar-este-questionario-encerrou-por-favor-envie-o-questionario-para-conclui-lo'),
          10000000
        )
        this.navigateToLastQuestion()
      }
    },
    showProctoringInit(val) {
      if (val) {
        document.body.style.overflow = 'hidden'
        const popupContent = document.querySelector('.vs-popup--content')
        if (popupContent) {
          const dimensions = this.getElementDimensions('body')
          popupContent.style.height = `${dimensions.height - 100}px`
        }
      } else {
        document.body.style.overflow = ''
      }
    }
  },
  // --- METHODS START ---
  methods: {
    dataFields() {
      // Most important code in the component, this is used to render the questions.

      const singleSection = this._.get(this.questionnaire, 'single_section', false) || false
      const isRandomized = this._.get(this.questionnaire, 'is_randomized', false) || false

      const fields = this.answerQuestions

      if (this.questionnaire) {
        if (!singleSection) {

          // This is just to create the correct quantity of square questions indicators
          if (this.progressiveQuestionnaire) {
            while (fields.length < this.progressiveQuestionnaireCount) {
              fields.push({})
            }
          }

          return fields || []
        } else {
          return this.sections[this.presentedSectionIndex]
        }
      }

      return fields || []
    },
    updateWidth(width) {
      this.isSmallScreen = width <= 576
    },
    warnQuestionnaireRemainder(time) {
      const moment = this.$moment.duration(time * 1000)
      this.notifyWarning(this.$vs, `
      Faltam ${moment.humanize(false, MOMENT_THRESHOLDS)} para finalizar o questionário.
      `)
    },
    warnQuestionRemainder() {
      const time = this.simpleTimerComponent().time
      const moment = this.$moment.duration(time)
      this.notifyWarning(this.$vs, `
     {{ $t('faltam-moment-humanize-false-moment_thresholds-para-finalizar-a-questao', [moment.humanize(false, MOMENT_THRESHOLDS)]) }}      `)
    },
    checkQuestionnaireDuration(duration) {
      const reference = parseInt(this.questionnaireDuration) || 1
      const time = this._.get(duration, 'total', 0)

      const percent = parseFloat(time / reference * 100).toFixed(0)

      if (percent <= 10 && !this.questionnaireTimerWarned) {
        this.warnQuestionnaireRemainder(time)
        this.questionnaireTimerWarned = true
      }
    },
    checkQuestionTimeRatio(ratio) {
      const percentage = parseFloat(ratio * 100).toFixed(0)

      if (percentage >= 90 && !this.questionTimer10Warned) {
        this.warnQuestionRemainder()
        this.questionTimer10Warned = true
      } else if (percentage >= 80 && !this.questionTimer20Warned) {
        this.warnQuestionRemainder()
        this.questionTimer20Warned = true
      }
    },
    clickedQuestion() {
      const allDisabled = this.$store.state.formAnswer.disabled
      const currentDisabled = this.currentQuestionDisabled
      if (allDisabled && this.generalTimeZero) {
        this.$vs.dialog({
          color: 'warning',
          title: this.$t('atencao-1'),
          text: this.$t('nao-e-possivel-responder-essa-questao-porque-o-tempo-deste-questionario-ja-expirou'),
          acceptText: this.$t('ok-0'),
          accept: () => {}
        })

        return
      }

      if (currentDisabled) {
        this.$vs.dialog({
          color: 'warning',
          title: this.$t('atencao-2'),
          text: this.$t('o-tempo-para-responder-essa-questao-ja-expirou'),
          acceptText: this.$t('ok-1'),
          accept: () => {}
        })

      }
    },
    emitPauseEvent(justificationContent) {
      const event = new EnterQuestionnairePauseEvent({justification: justificationContent})
      this.$root.$emit('questionnairePause', event)
      this.showPauseForm = false
      if (this.questionnaireAnswer && this.started) {
        this.paused = true
      }
    },
    emitEntranceJustificationEvent(justificationContent) {
      const event = new QuestionnaireJustifyEntranceEvent({justification: justificationContent})
      this.$root.$emit('questionnaireEntranceJustification', event)
      this.promptEntranceJustification = false
    },
    leavePause(event) {
      if (this.questionnaireAnswer && this.started) {
        this.paused = false
      }
    },
    enqueueAnswer(answer) {
      this.processAnswerRequiredValidation(answer)
      // Remove answer of queue if exists
      if (this.answersQueue && this.answersQueue.length > 0) {
        const index = this.answersQueue.findIndex(element => element.item_id === answer.item_id)
        if (index >= 0) {
          this.answersQueue.splice(index, 1)
        }
      }

      this.answersQueue.push(answer)
      this.addOrUpdateAnswerToLocalStorage(answer)
    },
    addOrUpdateAnswerToLocalStorage(answer) {

      answer = {
        'id': answer['item_id'],
        'answer': answer['value']
      }

      // Recupere a lista atual de questões do localStorage
      const storedAnswers = JSON.parse(localStorage.getItem(this.storageAnswerKey) || '[]')

      // Verifique se a answer já existe na lista
      const existingAnswerIndex = storedAnswers.findIndex(a => a.id === answer.id)

      // Se a questão já existe, substitua a antiga pela nova
      if (existingAnswerIndex !== -1) {
        answer['sent_at'] = (new Date()).toISOString()
        storedAnswers[existingAnswerIndex] = answer
      } else {
        // Caso contrário, adicione a nova questão à lista
        storedAnswers.push(answer)
      }

      // Armazene a lista atualizada de questões no localStorage
      localStorage.setItem(this.storageAnswerKey, JSON.stringify(storedAnswers))
    },
    getStoredAnswers() {
      return JSON.parse(localStorage.getItem(this.storageAnswerKey) || '[]')
    },
    async sendUnsentAnswers() {
      try {
        // Recupere todas as chaves no localStorage que começam com `stored_answers_${this.questionnaire.id}`
        const storageKeys = Object.keys(localStorage).filter(key => key.startsWith(`stored_answers_${this.questionnaire.id}`))

        // Iterar sobre todas as chaves
        for (const storageKey of storageKeys) {

          // Extrair o answerId da storageKey
          const answerId = storageKey.split(':')[1]

          // Recupere as respostas armazenadas para a chave atual
          const storedAnswers = JSON.parse(localStorage.getItem(storageKey) || '[]')

          // Filtrar respostas que ainda não foram enviadas (sem o atributo sent_at)
          const unsentAnswers = storedAnswers.filter(answer => !answer.sent_at)

          if (unsentAnswers.length > 0) {
            try {
              await this.questionnaireAnswerService.sendReconcileAnswers(answerId, {
                'reconcileData': storedAnswers
              }).then(() => {
                // Remova a chave do localStorage após o envio bem-sucedido
                localStorage.removeItem(storageKey)
              })
            } catch (error) {
              // Tratar erros de rede ou de servidor conforme necessário
              console.error('Erro ao enviar respostas para o servidor:', error)
            }
          }
        }
      } catch (error) {
        console.error('Erro ao tentar enviar respostas não enviadas:', error)
      }
    },
    removeStorageKeyAnswer() {
      localStorage.removeItem(this.storageAnswerKey)
    },
    isBookmarked(question) {
      const questionId = this._.get(question, 'id')
      if (questionId) {
        return this._.get(this.bookmarks, questionId, false) || false
      }

      return false
    },
    revisionMarked(questionId, value) {
      if (this.isEmpty(questionId) || this.preview) return

      if (value) {
        this.questionnaireAnswerService.bookmarkQuestion(this.answerId, questionId)
      } else {
        this.questionnaireAnswerService.removeQuestionBookmark(this.answerId, questionId)
      }

      // Attention! bookmarks is a computed with getter and setter, its not a literal variable
      this.bookmarks = {
        id: questionId,
        value: value
      }

      this.$nextTick(() => {
        this.panelQuestionKey++
      })
    },
    getButtonNextElement() {
      return this.$utils.browser.getElementByClass('buttonnext')
    },
    proccessAnswerQueue() {
      setInterval(() => {

        if (this.answersQueue && this.answersQueue.length > 0) {
          const answer = this.answersQueue.splice(0, 1)[0]

          const params = {
            // userId: answer.user_id,
            answer: answer.value,
            practical_test_student_id: this.practical_test_student_id,
            section_id: this.section_id
          }

          this.questionnaireAnswerService.answer(answer.answer_id, answer.item_id, params).then(response => {
            console.log('question answered: ', answer)
            const isEmpty = this.checkIfAnswerIsEmpty(answer)
            let state = QUESTION_STATUS.ANSWERED

            if (isEmpty) state = QUESTION_STATUS.NOT_ANSWERED

            this.updateQuestionStatus(this._.get(answer, 'item_id'), state)
            this.addOrUpdateAnswerToLocalStorage(answer)
          }, error => {
            console.log('answer error, enqueueAnswer: ', answer)
            this.updateQuestionStatus(this._.get(answer, 'item_id'), QUESTION_STATUS.ERROR_TO_SAVE)
            // If the error is a notification error, we dont want to enqueue the request again.
            if (error.status !== 422) {
              this.enqueueAnswer(answer)
            } else {
              /**
               * If the error is indeed a notification error
               * and it has a certain specific code, we must zero the question timer.
               */
              this.$utils.serverError.onCodeDo(NotificationActionCodes.QUESTIONTIMEEXHAUSTED, error,
                (errorCode) => {
                  /**
                   * When we set the question timer to 0, it automatically
                   * triggers the onZero question event, making it be disabled.
                   */
                  this.questionTimer = 0
                }
              )
            }
          })
        }
      }, 3000)
    },
    getAnswerData() {
      if (this.$store.state.auth.isUserLoggedIn()) {
        this.$vs.loading()
        this.service.canAnswer(this.short_url_id, this.preview).then(
          response => {
            //console.log('canAnswer...', response)
            this.$vs.loading.close()
            this.questionnaire = response
            this.multipleAttempts = this.questionnaire.multiple_attempts

            this.durationShortage = this._.get(response, 'duration_shortage', false)

            this.sendUnsentAnswers()

            if (!this.validateEnvRestriction(this.questionnaire.env_restriction)) {
              this.startError = this.envRestrictionMessage(this.questionnaire.env_restriction)
            }

            if (this.questionnaire.starErrorMessage) {
              this.startError = this.questionnaire.starErrorMessage
            }

            if (this.questionnaire.startErrorCode) {
              this.startErrorCode = this.questionnaire.startErrorCode
            }

            const fullScreenCompability = this.$utils.browser.fullScreenCompability()
            if (this.questionnaire && this.questionnaire.force_fullscreen && !fullScreenCompability) {
              this.startError = this.$t('nao-e-possivel-realizar-esta-avaliacao-neste-browser-favor-usar-a-versao-mais-recente-do-firefox-chrome-ou-opera-para-desktop-e-notebook')
            }

            if (response && 'organization' in response) {
              this.organization = response.organization

              this.organizationLogo = this._.get(response, 'organization.logo')
            }

            this.questionnaireDescription()

            if (this.voiceControlEnabled && !this.voiceControlActivated) {
              this.showVoiceControlConfirmation = true
            }

            if (this.isReturning) {
              /**
              * 60000 is the constant to convert minutes to milliseconds
              * since 60 * min = seconds
              * seconds * 1000 = milliseconds
              * thus 60000 * min = milliseconds
              */
              this.questionnaire.answer_unfinished.timeout =
              (response.duration * 60000) -
              (response.since_creation * 1000)
            }

            this.showPassword = this.questionnaire.showPassword
            if (this.questionnaire.answer_unfinished !== undefined) {
              this.continue = true
            }
            this.showStart = true

            // if (this.isProctored && !this.preview) {
            //   this.rebuildServicesForProctoring()
            // }
          },
          responseError => {
            const data = JSON.parse(responseError.data)
            if (data && data.errors) {
              const errors = JSON.parse(data.errors)
              this.startError = errors[0]
            }
            this.$vs.loading.close()
          }
        )
      } else {
        this.notifyWarning(this.$vs, this.$t('voce-deve-entrar-na-plataforma-para-fazer-o-questionario'), 8000)
        this.$router.push('/login')
      }
    },
    rebuildServicesForProctoring() {
      this.service = ContentQuestionnaireService.build(this.$vs, process.env.VUE_APP_API_PROCTORING_BASE_URL)
      this.questionnaireAnswerService = QuestionnaireAnswerService.build(this.$vs, process.env.VUE_APP_API_PROCTORING_BASE_URL)
    },
    start(e) {

      if (this.isProctored && !this.preview) {

        if (this.isElectron()) {

          this.forceFullscreen()
          this.enableScreenShare()
          this.enableUserMedia()

          setTimeout(() => {
            this.showProctoringInit = true
          }, 1000)

        } else {
          this.showProctoringInit = true
        }

      } else {
        this.startQuestionnaire()
      }

    },
    startPracticalTest() {

      this.$vs.loading()
      this.questionnaireAnswerService
        .startPracticalTest(this.practical_test_student_id, this.section_id)
        .then(
          response => {
            this.questionnaireAnswer = response
            this.questionnaire = response.content_questionnaire
            this.fillAnswers(this.dataFields())
            this.started = true

            this.$store.commit('formAnswer/ENABLE_FORM_ANSWER')
            this.proccessAnswerQueue()
            this.$vs.loading.close()

          },
          error => {
            this.$vs.loading.close()
            history.back()
          }
        )
    },
    startQuestionnaire() {

      /**
       * This condition prevents the user from starting the questionnaire
       * by unlawfully re-enabling the button through devTools.
       *
       * But if it is preview, which only the teacher can access, disregard this rule.
       */
      if ((!this.startEnabled || this.startError) && !this.preview) return

      if (!this.preview && (!this.isProctored || (this.isProctored && this.userPicture !== null && this.micSuccess))) {

        const startButton = document.querySelector('.start-questionnaire')
        if (startButton) {
          this.$vs.loading({
            text: this.$t('iniciando-questionario'),
            container: startButton,
            scale: 0.6
          })
        }

        this.questionnaireAnswerService
          .start(this.questionnaire.id, this.password)
          .then(
            response => {
              this.questionnaireAnswer = response
              this.fillAnswers(this.answerQuestions)
              this.started = true

              this.proccessAnswerQueue()

              this.$nextTick(() => {
                if (this.hasTemplate) {
                  this.startTemplateConn()
                }
              })

              const returnedPause = this._.get(response, 'returned_pause', false)
              const entranceJustified = this._.get(response, 'entrance_justified', false)

              if (this.questionnaire.start_at && this.entranceJustificationTime && !entranceJustified) {
                const now = this.$moment()
                const questionnaireStart = this.$moment(this.questionnaire.start_at)
                const entranceTime = this.entranceJustificationTime
                const questionnaireLastEntranceMoment = questionnaireStart.add(entranceTime, 'minutes')

                if (now.isAfter(questionnaireLastEntranceMoment)) {
                  this.promptEntranceJustification = true
                }
              }

              if (returnedPause) {
                this.notifySuccess(this.$vs, this.$t('voce-retornou-da-pausa-com-sucesso'), 8000)
              }

              if (this.micSoundInterval) {
                clearInterval(this.micSoundInterval)
              }

              if (this.isElectron()) {
                if (this.questionnaire && !this.isEmpty(this.questionnaire.force_fullscreen) && this.questionnaire.force_fullscreen) {
                  this.forceFullscreen()
                }
              } else {
                this.$nextTick(() => {
                  this.enableBrowserFullscreen()
                })
              }

              window.addEventListener('beforeunload', this.leave)

              // eslint-disable-next-line no-constant-condition
              if (true || !this.tourStarted('questionnaireAnswerSidebar')) {
                setTimeout(() => {
                  this.startTour('questionnaireAnswerSidebar')
                }, 1000)
              }

              // If screen is small
              const breakpoint = this.getCurrentBreakpoint()
              if (breakpoint === null || breakpoint === 'sm') {
                setTimeout(() => {
                  this.activeSidebar = false
                }, 5000)
              }

              // Proctoring
              if (this.isProctored && !this.preview && (response && response.proctoring && response.proctoring.proctor_key)) {
                this.showProctoringInit = false
                this.startStreamCapture(response.proctoring.proctor_key)

                const formData = new FormData()
                formData.append('parent_type', 'App\\Models\\Cnt\\QuestionnaireAnswer')
                formData.append('media_collection', 'proctoring')
                formData.append('replace', 'true')

                this.userPicture.toBlob((blob) => {
                  formData.append('file', blob, `proctoring-picture-${this.questionnaireAnswer.id}.png`)
                  const mediaService = MediaService.build(this.$vs)
                  mediaService.upload(this.questionnaireAnswer.id, formData)
                })

              }

              if (this.progressiveQuestionnaire) {
                this.progressiveQuestionnaireCount = this.questionnaireAnswer.questions_count
              }

              /**
               * Check if the backend signaled that this user must continue from a specific
               * question or section.
               */
              if ('continue_from' in response) {
                if (!this.questionnaire.single_section) {
                  // If it is single_question we get the supposed field index to continue with.
                  const fieldIndexToPresent = this.dataFields().findIndex((field) => field.id === response.continue_from.id)

                  if (fieldIndexToPresent !== -1 && 'offset' in response.continue_from && response.continue_from.offset !== null) {
                    const offset = parseInt(response.continue_from.offset)

                    const questionPositionToShow = (
                      (fieldIndexToPresent + offset) in this.dataFields() ?
                        fieldIndexToPresent + offset :
                        fieldIndexToPresent
                    )

                    this.presentedFieldIndex = questionPositionToShow
                    this.presentedField = this.dataFields()[questionPositionToShow]
                  }

                } else {
                  const sectionIndexToPresent = getQuestionSection(response.continue_from.id, this.questions)

                  if (sectionIndexToPresent !== -1) {
                    this.presentedSectionIndex = sectionIndexToPresent

                    // Configured to have sections but doesn't have any section
                    if (this.sections.length === 0) {
                      this.presentedSectionIndex--
                    }
                  }

                }

                this.reRenderSingleQuestion()
              }

              /** Check if there are any question timers in the request, if there is, it fills
               *  the data property "questionTimers" with the timers.
               */
              if (this.questionnaire && this.questionnaire.single_question && 'timers' in response) {
                this.questionTimers = response.timers
              }

              this.$store.commit('formAnswer/ENABLE_FORM_ANSWER')

              if (startButton) {
                this.$vs.loading.close(startButton)
              }

              // If we are to show by single question, set the active question here.
              if (this.showSingleQuestion) {
                /**
                 * We dont rely on the answerQuestions computed here cause it might not have
                 * been evaluated yet.
                 */
                const questions = this._.get(this.questionnaireAnswer, 'questions', []) || []
                if (questions && this._.isArray(questions) && questions.length > 0) {
                  this.presentedField = questions[this.presentedFieldIndex]
                }
              }

              this.$vs.loading.close()

              // Blocks any navigation from happening.
              this.$store.dispatch('freezeRouter')
            },
            error => {
              this.password = ''
              if (startButton) {
                this.$vs.loading.close(startButton)
              }
            }
          )
      } else {
        // Fake preview answer
        let questions = []
        const questionnaireData = this._.get(this.questionnaire, 'data', null) || null
        if (questionnaireData) {
          questions = this._.get(JSON.parse(questionnaireData), 'fields', []) || []
        } else {
          this.notifyError(this.$vs, this.$t('erro-questionario-com-dados-vazios'))
        }
        this.questionnaireAnswer = {
          id: this.$uuidKey(),
          questions: questions
        }

        if (this.showSingleQuestion) {
          /**
           * We dont rely on the answerQuestions computed here cause it might not have
           * been evaluated yet.
           */
          const questions = this._.get(this.questionnaireAnswer, 'questions', []) || []
          if (questions && this._.isArray(questions) && questions.length > 0) {
            this.presentedField = questions[this.presentedFieldIndex]
          }
        }

        this.started = true
      }

    },
    simpleTimerComponent() {
      return this.$refs['simpleTimer']
    },
    startQuestionTimer(questionId) {
      console.log('tentando iniciar o timer, componente é: ', this.simpleTimerComponent())
      if (this.questionTimers && questionId in this.questionTimers && this.simpleTimerComponent()) {
        if (this.questionTimers[questionId] <= 0) {
          this.currentQuestionTimerZeroed()
        } else {
          /**
           * SimpleTimerComponent takes milliseconds as time,
           * so we multiply the timer from the backend
           */
          this.simpleTimerComponent().startCountdown(this.questionTimers[questionId] * 1000)
        }
      }
    },
    disableQuestionTimer() {
      this.questionTimerEnabled = false
    },
    enableQuestionTimer() {
      if (
        this.questionnaire.single_question &&
        this.presentedField &&
        'id' in this.presentedField &&
        this.presentedField.id &&
        this.questionTimers[this.presentedField.id] !== null
      ) {
        this.startQuestionTimer(this.presentedField.id)
        this.questionTimerEnabled = true
      } else {
        console.warn('Cant enable question timer...')
      }
    },
    currentQuestionTimerZeroed() {
      this.questionTimerZeroed = true
      this.notifyWarning(this.$vs, this.$t('o-tempo-dessa-questao-acabou'), 6000)
      this.disableCurrentQuestion()
    },
    requestFullscreen() {
      if (screenfull.isEnabled) {
        this.fullscreenActivePrompt = false
        if (this.autoFinishInterval) {
          clearInterval(this.autoFinishInterval)
        }
        screenfull.request(document.documentElement, {
          navigationUI: 'hide'
        })
      }
    },
    enableBrowserFullscreen() {

      if (this.questionnaire && !this.isEmpty(this.questionnaire.force_fullscreen) && this.questionnaire.force_fullscreen) {
        this.requestFullscreen()
        screenfull.on('change', () => {
          this.browserChange()
        })

        this.$utils.browser.addVisibilityChange(() => {
          if (!this.isEmpty(this.questionnaire.force_fullscreen)) {
            screenfull.exit()
          }
        })

        window.addEventListener('blur', () => {
          if (!this.isEmpty(this.questionnaire.force_fullscreen)) {
            screenfull.exit()
          }
        })
      }

    },
    browserChange() {
      if (!screenfull.isFullscreen && this.isEmpty(this.questionnaireAnswer.finished_at_formatted) && this.questionnaire.force_fullscreen === 'severe') {
        this.finishInterval('fullscreen_block')
      } else if (!screenfull.isFullscreen && (this.questionnaireAnswer && this.isEmpty(this.questionnaireAnswer.finished_at_formatted))) {
        const time = this.forceFullscreenTime()

        this.fullscreenActivePrompt = true

        this.autoFinishInterval = setInterval(() => {
          this.fullscreenActivePrompt = false
          this.finishInterval('fullscreen_block')
        }, time * 1000)
      }
    },
    exitBrowserFullscreen() {
      if (this.autoFinishInterval) {
        console.log('clear interval autoFinishInterval 1')
        clearInterval(this.autoFinishInterval)
      }

      if (!this.isEmpty(this.questionnaire.force_fullscreen)) {
        screenfull.exit()
      }
    },
    leave(e) {
      const target = e || window.event
      console.log('target...', target)
      target.preventDefault()
      target.returnValue = this.$t('tem-certeza-que-deseja-sair')
      return () => { return this.$t('tem-certeza-que-deseja-sair-0') }
    },
    suffleQuestions(questions) {
      let newQuestionArray = []
      let start = -1
      let end = 0
      if (questions.length > 0) {
        do {
          start = this.findSectionInterval(questions, start)
          end = this.findSectionInterval(questions, start)
          const sectionQuestion = questions.slice(start, start + 1)
          const sectionArray =
            end !== 0
              ? questions.slice(start + 1, end)
              : questions.slice(start + 1, questions.length)

          newQuestionArray = newQuestionArray.concat(sectionQuestion)
          if (sectionArray.length > 0) {
            newQuestionArray = newQuestionArray.concat(
              this.suffle(sectionArray)
            )
          }
        } while (end !== 0)
      }

      return newQuestionArray
    },
    findSectionInterval(array, start) {
      for (let index = start + 1; index < array.length; index++) {
        const element = array[index]
        if (element.type === 'section') {
          return index
        }
      }
      return 0
    },
    nextQuestion() {
      console.log('Next question: by next button')
      if (this.validateRequiredQuestions() || this.preview) {
        if (!this.questionnaire.single_section) {
          if (this.showDone) {
            this.finishConfirm()
          } else if (!this.preview && this.presentedField && this.presentedField.id) {

            /**
             * Before we change the current presented question we send the request
             * that creates an event of type "next" to it.
             */
            this.questionnaireAnswerService.nextQuestion(this.questionnaireAnswer.id, this.presentedField.id).then(response => {

              if (this.progressiveQuestionnaire) {

                // Add next_question to questions array
                const presentedFieldIndex = ++this.presentedFieldIndex
                this.questionnaireAnswer.questions[presentedFieldIndex] = (response.event.next_question)
                const questions = this._.get(this.questionnaireAnswer, 'questions', []) || []
                if (questions && this._.isArray(questions) && questions.length > 0) {
                  this.presentedField = questions[presentedFieldIndex]
                }
              } else {
                this.presentedField = this.dataFields()[++this.presentedFieldIndex]
              }

            })

          } else if (this.preview) {
            ++this.presentedFieldIndex
            this.reRenderSingleQuestion()
          }
        } else if (this.showSectionDone) {
          this.finishConfirm()
        } else {
          this.presentedSectionIndex++
          this.reRenderSingleQuestion()
        }
      }
      this.scrollToTop()
    },
    reRenderSingleQuestion() {
      this.key++
    },
    /**
     * Gets the currently displayed single question component through
     * its reference then sets its disability if it has the required method.
     */
    disableCurrentQuestion() {
      this.currentQuestionDisabled = true
    },
    enableCurrentQuestion() {
      this.currentQuestionDisabled = false
    },
    finishConfirm(index) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: this.$t('confirmacao-finalizar-questionario'),
        acceptText: this.$t('sim'),
        cancelText: this.$t('nao'),
        text:
          this.$t('apos-o-envio-o-questionario-sera-finalizado-e-nao-sera-mais-possivel-continuar-tem-certeza-que-deseja-enviar-as-respostas-e-finalizar'),
        accept: () => this.finishInterval()
      })
    },
    navigateToLastQuestion() {
      if (!this.questionnaire.single_section) {
        this.presentedFieldIndex = this.dataFields().length - 1
        this.presentedField = this.dataFields()[this.presentedFieldIndex]
      } else {
        this.presentedSectionIndex = this.sections.length - 1
      }
      this.reRenderSingleQuestion()
    },
    finishInterval(reason = 'user') {
      if (!this.preview) {
        this.$vs.loading({
          text: this.$t('finalizando-questionario-nao-feche-esta-tela')
        })

        // Stop proctoring capture
        if (this.isProctored && !this.preview) {
          this.stopCapture()
        }

        // Check if all answers and all stream chunks are sent
        this.finishIntervalRef = setInterval(() => {
          const emptyRecordQueue = true//this.streamRecorder && this.streamRecorder.queue ? this.streamRecorder.queue.length === 0 : false
          const emptyEventQueue = this.eventQueueSize === 0
          if (emptyEventQueue && (this.queueSize === 0 || this.isEmpty(this.queueSize)) && (emptyRecordQueue || this.isEmpty(this.streamRecorder))) {
            if (this.streamRecorder) {
              this.streamRecorder.stopSend(() => {
                this.finish(reason)
              })
            } else {
              this.finish(reason)
            }
          }
        }, 1000)
      } else {
        this.$vs.dialog({
          color: 'success',
          title: this.$t('concluido'),
          text: this.$t('parabens-previa-finalizada-com-sucesso-nenhuma-resposta-foi-salva'),
          acceptText: this.$t('ok-fechar'),
          accept: () => {
            window.close()
          }
        })
      }
    },
    finish(reason) {
      if (!this.finishInProgress) {
        this.finishInProgress = true

        if (!this.isPracticalTest) {
          this.questionnaireAnswerService
            .finish(this.questionnaireAnswer.id, {
              'reason': reason,
              'reconcileData': this.getStoredAnswers()
            })
            .then(response => {
              clearInterval(this.finishIntervalRef)

              this.questionnaireAnswer.finished_at_formatted = response.finished_at

              this.fullscreenActivePrompt = false
              this.notifySuccess(
                this.$vs,
                this.$t('parabens-questionario-finalizado-com-sucesso'),
                5000,
                'center-top'
              )

              this.devToolsOpened = false

              if (this.isElectron()) {
                if (this.questionnaire && !this.isEmpty(this.questionnaire.force_fullscreen) && this.questionnaire.force_fullscreen) {
                  this.exitFullScreen()
                }
              } else {
                this.exitBrowserFullscreen()
              }

              if (this.connectionSpeedInterval) {
                clearInterval(this.connectionSpeedInterval)
              }

              this.stopVoiceControl()

              this.removeStorageKeyAnswer()

              this.$store.dispatch('thawRouter').then((success) => {
                if (this.questionnaire.redirect_to && !this.preview) {

                  this.$vs.loading.close()
                  this.$vs.loading({
                    text: this.$t('voce-sera-redirecionado-a-para-um-novo-questionario')
                  })

                  setTimeout(() => {
                    this.$router.push(this.questionnaire.redirect_to)
                    this.$vs.loading.close()
                  }, 3000)

                } else if (this.lead_id && !this.preview) {
                  this.$router.push(`${window.location.origin}/#/form/${this.short_url_id}/results/${this.questionnaireAnswer.id}/full`)
                } else {
                  this.$router.push('/questionnaires/answers')
                  if (this.$acl.hasPermission('questionnaires.menu.answers')) {
                    this.$router.push('/questionnaires/answers')
                  } else {
                    this.$router.push('/')
                  }
                }
              })

              this.$vs.loading.close()
            }).finally(() => {
              this.finishInProgress = false
            })
        } else {

          this.questionnaireAnswerService
            .finishPracticalTest(this.questionnaireAnswer.id, this.section_id, this.practical_test_student_id)
            .then(response => {
              clearInterval(this.finishIntervalRef)

              this.questionnaireAnswer.finished_at_formatted = response.finished_at

              this.fullscreenActivePrompt = false
              this.notifySuccess(
                this.$vs,
                this.$t('parabens-questionario-finalizado-com-sucesso'),
                5000,
                'center-top'
              )

              this.devToolsOpened = false

              this.$store.dispatch('thawRouter').then((success) => {
                history.back()
              })

              this.$vs.loading.close()
            }).finally(() => {
              this.finishInProgress = false
            })

        }
      }
    },
    previousQuestion() {
      const cannotReturn = this.cannotGoBack

      /**
       * If the user shouldnt be allowed to return per the questionnaire's settings,
       * we wont even process this method.
       */
      if (!cannotReturn) {
        if (!this.questionnaire.single_section) {
          this.presentedField = this.dataFields()[--this.presentedFieldIndex]
          if (!this.preview) {
            const questionId = this.presentedField.id
            this.questionnaireAnswerService.previousQuestion(this.questionnaireAnswer.id, questionId)
          }
        } else {
          this.presentedSectionIndex--
        }
        this.reRenderSingleQuestion()
      } else {
        // TODO Throw event
        this.notifyError(this.$vs, this.$t('ops-nao-foi-possivel-voltar-ne'), 100000)
      }

      this.scrollToTop()
    },
    showQuestionAsRequired(questionId) {
      return (
        this.showRequiredQuestions.find((id) => {
          return id === questionId
        })
      ) || false
    },
    /**
     * Remove question from the showRequiredQuestions array,
     * making it so that it stops showing as a required question
     * requiring... well, an answer.
     */
    removeQuestionFromRequired(questionId) {

      // Remove required indicator when PER QUESTION.
      if (this.presentedField && 'id' in this.presentedField && this.presentedField.id === questionId) {
        this.presentedField.showRequiredIndicator = false
      }

      // Remove required indicator on requiredQuestions array, normally used when showing ALL or PER SECTION.
      const position = this.showRequiredQuestions.findIndex((id) => {
        return id === questionId
      })

      if (position !== -1) {
        this.showRequiredQuestions.splice(position, 1)
        return 1
      }

      return 0
    },
    processAnswerRequiredValidation(answer) {

      let type = null
      let question = null

      if (this.dataFields() && this._.isArray(this.dataFields()) && answer && 'item_id' in answer) {
        question = this.dataFields().find((field) => {
          return field.id === answer.item_id
        })
        if (question && 'type' in question) {
          type = question.type
        }
      }

      switch (type) {
      case 'gap':
        if (this.validateRequiredGap(question)) this.removeQuestionFromRequired(answer.item_id)
        break
      default:
        if (!this.isEmpty(answer.value)) this.removeQuestionFromRequired(answer.item_id)
      }
    },
    validateRequiredGap(field) {

      if (field.data && !field.data.required) {
        return true
      } else if (field.data && field.data.required && this.isEmpty(field.answer)) {
        return false
      }

      /**
       * The gap is valid if none of its gap answers are empty OR
       * if this questionnaire has time per question and the timer is at zero.
       */ //
      const valid = !(
        // If any gap answer is empty...
        field.answer.some((gap) => {
          let gapAnswer = gap.answer
          if (isString(gapAnswer)) {
            // We trim this to prevent blank spaces being considered as valid content.
            gapAnswer = gapAnswer.trim()
          }
          return this.isEmpty(gapAnswer)
        })
      ) || (
        this.hasAnyQuestionTimer &&
        this.questionTimerZeroed
      )

      return valid
    },
    validateRequiredQuestions() {
      let valid = true
      if (!this.questionnaireDisabled) {
        if (this.showSingleQuestion) {
          console.log('this.presentedField', this.presentedField)
          if (this.presentedField.type === 'section') {
            return valid
          } else if (this.presentedField.data.required) {
            let answer = this.presentedField && this.presentedField.answer
            if (!this.isEmpty(answer) && typeof answer === 'string') {
              answer = answer.trim()
            }

            //--------------------------- GAP VALIDATION ----------------------------//
            if (answer && isArray(answer) && this.presentedField.type === 'gap') {
              valid = this.validateRequiredGap(this.presentedField)
            //-----------------------------------------------------------------------//
            } else {
              valid = (!this.isEmpty(answer) && answer.length > 0) || (this.hasAnyQuestionTimer && this.questionTimerZeroed)
            }

            // If the currently presented question isnt valid, mark it as required.
            if (!valid) {
              this.presentedField.showRequiredIndicator = true
            }
          }
        } else {
          for (let index = 0; index < this.dataFields().length; index++) {
            const element = this.dataFields()[index]
            if (element.data.required && this.isEmpty(element.answer) && element.type !== 'gap') {
              const alreadyShowingAsRequired = this.showRequiredQuestions.find(
                (val) => val === element.id
              )
              if (!alreadyShowingAsRequired) {
                this.showRequiredQuestions.push(element.id)
              }
              valid = false
            } else if (element.type === 'gap') {
              valid = this.validateRequiredGap(element)
              const alreadyShowingAsRequired = this.showRequiredQuestions.find(
                (val) => val === element.id
              )
              if (!valid && !alreadyShowingAsRequired) {
                this.showRequiredQuestions.push(element.id)
              }
            }
          }
        }

        if (!valid && !this.preview) {
          this.reRenderSingleQuestion()
          const message = this.showSingleQuestion
            ? this.$t('questao-obrigatoria-favor-preencher-para-continuar')
            : this.$t('e-necessario-preencher-todos-as-questoes-obrigatorias')
          this.notifyWarning(this.$vs, message, 5000)
        }
      }
      return valid
    },
    fillAnswers(fields) {
      if (fields) {
        fields.forEach(field => {
          const questionnaireAnswer = this.findAnswerById(field.id)
          if (questionnaireAnswer) {
            field.answer = questionnaireAnswer.answer
            if (field.answer) {
              const id = this._.get(field, 'id')
              this.updateQuestionStatus(id, QUESTION_STATUS.ANSWERED)
            }
          }
        })
        this.reRenderSingleQuestion()
      }
    },
    findAnswerById(id) {
      const answers = this.questionnaireAnswer
        ? JSON.parse(this.questionnaireAnswer.data)
        : []
      for (let index = 0; index < answers.length; index++) {
        const element = answers[index]
        if (element.id === id) {
          return element
        }
      }
      return null
    },
    questionnaireDescription() {
      this.description = this.questionnaire
        ? this.questionnaire.description
        : ''
      this.parseFormula('descriptionElement')
      return this.embebedToFrame(this.description)
    },
    questionsQuantity(questions) {
      if (this.progressiveQuestionnaire) {
        return this.progressiveQuestionnaireCount
      } else {
        questions = questions ? questions.filter(question => {
          return question.type !== 'section'
        }) : []
        return questions.length
      }
    },
    sectionQuantity(questions) {
      questions = questions
        ? questions.filter(question => {
          return question && question.type === 'section'
        })
        : []
      return questions.length
    },
    countQuestionsBeforeSection(sectionIndex) {
      let qtd = 0
      for (let index = 0; index < sectionIndex; index++) {
        const section = this.sections[index]
        qtd = qtd + this.questionsQuantity(section)
      }
      return qtd + 1
    },
    countQuestionsBeforePresentedField(questions, fieldIndex) {
      let qtd = 0
      for (let index = 0; index <= fieldIndex; index++) {
        const question = questions[index]
        if (question) {
          qtd += question.type !== 'section' ? 1 : 0
        }
      }
      return qtd
    },
    questionNumber(index) {
      if (this.showSingleSection) {
        let nQuestion = this.countQuestionsBeforeSection(this.presentedSectionIndex)
        const qtdSection = this.sectionQuantity(this.questions)
        nQuestion = qtdSection === 0 ? nQuestion += index : nQuestion += index - 1
        return nQuestion
      }
      return this.countQuestionsBeforePresentedField(this.questions, index)
    },

    /** --- PROCTORING --- */

    enableScreenShare() {
      this.$vs.loading()

      const displayMediaOptions = {
        video: {
          cursor: 'motion',
          width: { max: 800 },
          height: { max: 600 },
          frameRate: { max: 15 }
        },
        audio: false
      }

      this.startScreenCapture(displayMediaOptions, this).then(stream => {
        if (this.isStreamFullScreen(stream)) {
          stream.getVideoTracks()[0].addEventListener('ended', () => {
            if (this.started) {
              this.finishInterval()
            } else {
              this.cancelProctoringInit()
            }
          })

          this.$vs.loading.close()
          this.screenShareStream = stream
          this.screenShareEnabled = true
        } else {
          this.stopStream(stream)
          this.notifyWarning(this.$vs, this.$t('voce-nao-compartilhou-a-tela-toda'), 10000, 'center-top')
        }

      }, error => {
        this.$vs.loading.close()
        this.captureScreenshareError = error
      })
    },
    enableUserMedia() {
      this.$vs.loading()

      this.micSuccess = false
      this.audioDevices = []
      this.videoDevices = []

      if (this.micSoundInterval) {
        clearInterval(this.micSoundInterval)
      }

      /**
       * Calling getUserMedia to get permission to enumerate devices. Some browsers like Chrome
       * don't gave access to enumerateDevices before user enable camera permission.
       */
      const constraints = {
        audio: { deviceId: undefined },
        video: { deviceId: undefined }
      }

      this.startCaptureUserMedia(constraints, this, null, true).then(tempStream => {
        this.stopStream(tempStream)
        this.getDevices()
      }, () => {
        this.getDevices()
      })
    },

    getDevices() {

      this.getDeviceVideoError = null
      this.getDeviceAudioError = null

      this.enumerateDevices().then(devices => {

        console.log('devices...', devices)

        devices = this.$utils.array.uniqBy(devices, (item) => {
          return item.deviceId
        })

        devices.forEach(device => {
          if (device.kind === 'audioinput') {
            this.audioDevices.push(device)
          } else if (device.kind === 'videoinput') {
            this.videoDevices.push(device)
          }
        })

        if (this.audioDevices.length > 0) {
          if (!this.selectedAudioDeviceId) {
            this.selectedAudioDeviceId = this.audioDevices[0].deviceId
          }
        }

        if (this.videoDevices.length > 0) {
          if (!this.selectedVideoDeviceId) {
            this.selectedVideoDeviceId = this.videoDevices[0].deviceId
          }
        }

        this.userMediaEnabled = true
        this.captureVideoStream()

      }, error => {
        this.captureVideoStream()
      })
    },
    captureVideoStream() {
      const videoConstraits = {
        video: {
          deviceId: {
            exact: this.selectedVideoDeviceId
          },
          width: { max: 800 },  // Define a largura máxima do vídeo em pixels
          height: { max: 600 }, // Define a altura máxima do vídeo em pixels
          frameRate: { max: 15 } // Define a taxa máxima de quadros por segundo (fps)
        },
        audio: false
      }

      // Capture video stream
      this.startCaptureUserMedia(videoConstraits, this, 'video', false).then(stream => {

        this.userMediaStream = stream
        const waitNextScreenTimeout = setInterval(() => {
          const videoDocument = document.getElementById('video-document')
          if (videoDocument) {
            clearInterval(waitNextScreenTimeout)
            videoDocument.srcObject = stream
            videoDocument.play()
          }
        }, 100)

        this.captureAudioStream()

      }, error => {
        this.captureAudioStream()
        this.getDeviceVideoError = error
        this.$vs.loading.close()
      })
    },
    captureAudioStream() {

      const audioConstraits = {
        audio: {
          deviceId: {
            exact: this.selectedAudioDeviceId
          }
        },
        video: false
      }

      this.startCaptureUserMedia(audioConstraits, this, 'audio', false).then(stream => {

        if (stream.getAudioTracks() && stream.getAudioTracks()[0]) {
          stream.getAudioTracks()[0].enabled = true
        }

        if (this.userMediaStream) {
          this.userMediaStream.addTrack(stream.getAudioTracks()[0])
        }

        const audioContext = new AudioContext()
        const analyser = audioContext.createAnalyser()
        audioContext.createMediaStreamSource(this.userMediaStream).connect(analyser)
        const pcmData = new Float32Array(analyser.fftSize)

        this.micSoundInterval = setInterval(() => {

          if (analyser && analyser.getFloatTimeDomainData && typeof analyser.getFloatTimeDomainData === 'function') {
            analyser.getFloatTimeDomainData(pcmData)
          }

          let sumSquares = 0.0
          for (const amplitude of pcmData) { sumSquares += amplitude * amplitude }
          this.micVolume = Math.sqrt(sumSquares / pcmData.length) * 1000

          if (this.micVolume > 30) {
            this.micSuccess = true
          }
        }, 40)

        this.userMediaEnabled = true
        this.$vs.loading.close()

      }, error => {
        this.getDeviceAudioError = error
        this.$vs.loading.close()

        if ((this.audioDevices && this.audioDevices.length > 0)
              && this.videoDevices && this.videoDevices.length > 0) {
          this.userMediaEnabled = true
        } else {
          this.captureUserMediaError = true
        }
      })
    },

    cancelProctoringInit() {
      this.stopCapture()
      this.showProctoringInit = false
      this.screenShareStream = null
      this.userMediaStream = null
      this.mergedMediaStream = null
      this.screenShareEnabled = false
      this.userMediaEnabled = false
      this.showVideoPlayer = false
      this.presentVideoPlayer = false
      this.micSuccess = false
      this.userPicture = null
      this.$vs.loading.close()
    },

    startStreamCapture(proctorKey) {

      this.showVideoPlayer = true
      this.presentVideoPlayer = true
      this.supportKey++

      this.mergedMediaStream = this.mergeStreams(this.userMediaStream, this.screenShareStream)

      if (this.isProctoringRecord) {

        this.mergedMediaStream.result.getAudioTracks()[0].enabled = true
        this.streamRecorder = new StreamRecorder(this.$vs, this.mergedMediaStream.result, `video-${this.questionnaire.id}-${this.questionnaireAnswer.id}-${proctorKey}`)
        const options = this.getRecordMimeType()
        // options['audioBitsPerSecond'] = 128000
        // options['videoBitsPerSecond'] = 2500000

        this.streamRecorder.startRecording(options, (data) => {
          const event = new StartProctoringRecordingEvent({emitted_at: data.emitted_at})
          this.$root.$emit('startProctoringRecording', event)
        }).then(queue => {
          console.log('screenShareStream queue stopped', queue)
        }, error => {
          console.log('start record error', error)
        })

        this.streamRecorder.sendData()
      }

      if (this.isProctoringLive) {
        setTimeout(() => {
          this.createProctoringCamPeer()
        }, 1000)
      }

      const waitVideoTimeout = setInterval(() => {
        const video = document.getElementById('video')
        if (video) {
          clearInterval(waitVideoTimeout)
          video.srcObject = this.userMediaStream
          video.play()
        }
      }, 100)
    },

    stopCapture() {
      if (this.userMediaStream) {
        this.stopStream(this.userMediaStream)
        this.showVideoPlayer = false
        this.presentVideoPlayer = false
        this.supportKey++
      }

      if (this.screenShareStream) {
        this.stopStream(this.screenShareStream)
      }

      // if (this.streamRecorder) {
      //   this.streamRecorder.stopRecording()
      // }

      if (this.mergedMediaStream) {
        this.mergedMediaStream.destroy()
        if (this.mergedMediaStream.result) {
          this.stopStream(this.mergedMediaStream.result)
        }
      }

      if (this.micSoundInterval) {
        clearInterval(this.micSoundInterval)
      }

      const videoDocument = document.getElementById('video-document')
      if (videoDocument) {
        videoDocument.srcObject = null
      }

    },

    takePicture() {
      const { width, height } = this.userMediaStream.getVideoTracks()[0].getSettings()
      const video = document.getElementById('video-document')
      const canvasPicture = document.getElementById('picture')
      canvasPicture.width = width
      canvasPicture.height = height
      canvasPicture.getContext('2d').drawImage(video, 0, 0, width, height)
      const img = document.getElementById('picture_img')
      img.src = canvasPicture.toDataURL('image/png')
      this.userPicture = document.getElementById('picture')
    },

    toggleVideoPlayer() {
      this.presentVideoPlayer = !this.presentVideoPlayer
      this.supportKey++
    },
    createProctoringCamPeer() {

      console.log('creating peer... ', this.pqUserId)
      this.camPeer = new Peer(this.pqUserId, {
        host: process.env.VUE_APP_PEER_SERVER_URL,
        port: process.env.VUE_APP_PEER_SERVER_PORT,
        path: process.env.VUE_APP_PEER_PATH,
        key: process.env.VUE_APP_PEER_KEY,
        config: {'iceServers': [
          { url: process.env.VUE_APP_STUN_SERVER },
          { url: `turn:${process.env.VUE_APP_PEER_SERVER_URL}:${process.env.VUE_APP_TURN_SERVER_PORT}`, username: process.env.VUE_APP_TURN_SERVER_USERNAME, credential: process.env.VUE_APP_TURN_SERVER_CREDENTIAL }
        ]},
        debug: 3
      })

      this.camPeer.on('call', (call) => {
        call.answer(this.mergedMediaStream.result)
        this.connectedPeer = call.peer
        call.on('stream', (remoteStream) => {
          this.monitorRemoteStream = remoteStream
        })

        const pcCam = call.peerConnection
        if (pcCam) {
          pcCam.oniceconnectionstatechange = () => {
            console.log('pcCam', pcCam, pcCam.iceConnectionState)

            if (pcCam.iceConnectionState === 'failed') {
              this.camPeer.destroy()
              this.camPeer = null
              this.createProctoringCamPeer()
            }

            if (pcCam.iceConnectionState === 'disconnected') {
              console.log('Cam disconnected')
            }
          }
        }
      })

      this.camPeer.on('connection', (conn) => {
        console.log('connection received...', conn, this.connectedPeer)
        this.connectedPeer = conn.peer

        // Don't remove this try catch, all code after this assignment brokes.
        try {
          this.supportConnection = conn
        // eslint-disable-next-line no-empty
        } catch (e) { }
        this.supportKey++
        this.prepareSupportConnection()
      })

      this.camPeer.on('open', (id) => {
        console.log('open', id)
        this.camConnectError = false
        this.camConnectInProgress = false
      })

      this.camPeer.on('disconnected', (peer) => {
        console.log('camPeer disconnected...', peer)

        this.runDebounced(() => {
          this.$exceptionService.quickRegister(
            this.$t('proctoring-conexao-do-participante-desconectada'),
            'proctoring_userconnection_disconnected',
            {
              pqUserId: this.pqUserId
            }
          )
        })

      })

      this.camPeer.on('error', (error) => {
        this.runDebounced(() => {
          this.$exceptionService.quickRegister(
            this.$t('proctoring-erro-na-conexao-do-participante'),
            'proctoring_userconnection_error',
            {error: error, pqUserId: this.pqUserId}
          )
          setTimeout(() => {
            console.log('peer error...', error)
            this.camPeer.destroy()
            this.camPeer = null
            this.createProctoringCamPeer()
          }, 1000)
        })
      })
    },
    forceFullscreenTime() {

      let time = -1
      if (this.questionnaire && !this.isEmpty(this.questionnaire.force_fullscreen)) {

        switch (this.questionnaire.force_fullscreen) {
        case 'lenient':
          time = 30
          break

        case 'moderate':
          time = 15
          break

        case 'severe':
          time = 0
          break

        default:
          time = -1
          break
        }
      }
      return time
    },

    openCallSupport() {

      if (!this.waitingSupport) {
        const conn = this.getSupportConnection()
        if (conn) {

          conn.send({
            type: PROCTORING_SOCKET_TYPE.CallSupport,
            peerId: this.pqUserId,
            user_id: this.user_id
          })

          this.waitingSupport = true
        }
      } else {
        this.closeCallSupport()
      }

    },
    closeCallSupport() {

      if (this.callSupportOpened || this.waitingSupport) {
        const conn = this.getSupportConnection()
        if (conn) {
          conn.send({
            type: PROCTORING_SOCKET_TYPE.OpenSupportCanceled,
            peerId: this.pqUserId,
            user_id: this.user_id
          })
        }
      }

      const monitorVideoPlayer = document.getElementById('monitor-video-player')
      if (monitorVideoPlayer) {
        monitorVideoPlayer.remove()
      }

      this.callSupportOpened = false
      this.showMonitorVideoPlayer = false
      this.waitingSupport = false
      this.supportInProgress = null
      this.supportKey++
    },
    getSupportConnection() {
      console.log('getSupportConnection...')
      if (this.supportConnection && this.supportConnection.open) {
        return this.supportConnection
      }

      if (this.connectedPeer) {
        try {
          this.supportConnection = this.camPeer.connect(this.connectedPeer)
          this.supportKey++
          return this.supportConnection
        } catch (e) {
          return null
        }
      }
      return null
    },
    prepareSupportConnection() {
      const conn = this.getSupportConnection()
      if (conn) {
        conn.on('data', (data) => {
          console.log('data...', data)
          if (data && data.type === PROCTORING_SOCKET_TYPE.OpenSupportConfirmed) {
            this.playPhoneRing()
            this.supportInProgress = {
              monitorName: data.user_name
            }
            this.callSupportOpened = true
            this.showMonitorVideoPlayer = true
            this.waitingSupport = false
            this.presentVideoPlayer = true
            this.supportKey++

            setTimeout(() => {
              const monitorVideoPlayerDiv = this.$refs['monitoVideoPlayerDiv']
              if (monitorVideoPlayerDiv) {
                const videoElement = document.createElement('video')
                videoElement.className = this.$t('monitor-video-player-transform')
                videoElement.id = 'monitor-video-player'
                videoElement.srcObject = this.monitorRemoteStream
                videoElement.autoplay = true
                monitorVideoPlayerDiv.appendChild(videoElement)
              }
            }, 1000)

          } else if (data && data.type === PROCTORING_SOCKET_TYPE.OpenSupportCanceled) {
            this.supportInProgress = null
            this.callSupportOpened = false
            this.showMonitorVideoPlayer = false
            this.waitingSupport = false
            this.supportKey++

            const monitorVideoPlayer = this.$refs['monitoVideoPlayerDiv']
            if (monitorVideoPlayer) {
              while (monitorVideoPlayer.firstChild) {
                monitorVideoPlayer.removeChild(monitorVideoPlayer.lastChild)
              }
            }
          } else if (data && data.type === PROCTORING_SOCKET_TYPE.AskConnectionSpeed) {
            this.startSpeedConnection()
          }
        })
      }
    },
    startSpeedConnection() {

      this.connectionSpeedInterval = setInterval(() => {

        this.$utils.browser.detectConnectionSpeed().then(result => {

          if (!this.sentConnectionEvent) {
            const event = new ConnectionRateEvent(result)
            this.$root.$emit('connectionRate', event)
            this.sentConnectionEvent = true
          }

          const conn = this.getSupportConnection()
          if (conn) {
            conn.send({
              type: PROCTORING_SOCKET_TYPE.ConnectionSpeed,
              data: {
                result
              }
            })

            this.waitingSupport = true
          }

        }, error => {
          console.log('connection speed error', error)
        })

      }, 1000)
    },
    openDesktopApp() {
      this.$utils.browser.openDesktopApp(this.short_url_id, (success) => {
        if (!success) {
          // TODO Show desktop app download page
        }
      })
    },
    resumeIntervalQueue() {
      if (this.streamRecorder) {
        this.streamRecorder.resumeIntevalQueue()
      }
    },
    pauseIntevalQueue() {
      if (this.streamRecorder) {
        this.streamRecorder.pauseIntevalQueue()
      }
    },
    clearNavigationWarning() {
      this.showQuestionNavigationWarning = false
      this.navigationHold = null
    },
    navigationWarningProcceed() {
      if (this.navigationHold) {
        this.navigateToQuestion(this.navigationHold.questionId, this.navigationHold.index)
        this.clearNavigationWarning()
      }
    },
    navigationWarningCancel() {
      this.clearNavigationWarning()
    },
    checkQuestionNavigation(questionId, index) {
      console.log('Next question: by click question')
      if (this.questionnaire.single_question && !this.preview) {
        const valid = this.validateRequiredQuestions()
        if (!valid) return
      }

      const cannotReturn = this.cannotGoBack

      if (this.progressiveQuestionnaire) {
        return
      }

      if (this.questionnaire.single_section) {
        // TODO verify if the navigation is entering into another session domain.
        // Since when we Cannot Return it means we cant go back a session...
        // test that out later.
        this.navigateToQuestion(questionId, index)
        return
      }

      if (index < this.presentedFieldIndex) {
        // GOING BACK
        if (cannotReturn) {
          // If you cant go back... you can go back.
          this.notifyWarning(this.$vs, this.$t('nao-e-permitido-voltar'))
        } else {
          // Otherwise just plainly go back no problem.
          this.navigateToQuestion(questionId, index)
        }
      } else if (index === this.presentedFieldIndex) {
        // NOT CHANGING POSITION
      } else if (index > this.presentedFieldIndex) {
        // ADVANCING
        if (cannotReturn && !this.warnedDisallowReturn) {
          /**
           * If cannot return and the warning hasnt being disabled,
           * Cache the navigation and open the warning popup.
           */
          this.navigationHold = {
            questionId,
            index
          }
          this.showQuestionNavigationWarning = true
        } else {
          this.navigateToQuestion(questionId, index)
        }
      }


    },
    navigateToQuestion(questionId, index) {
      if (this.showSingleQuestion) {

        if (!this.questionnaire.single_section) {
          if (index < this.presentedFieldIndex) {
            this.presentedField = this.dataFields()[index]

            if (!this.preview) {
              this.questionnaireAnswerService.previousQuestion(this.questionnaireAnswer.id, questionId)
            }
          } else {
            this.presentedField = this.dataFields()[index]

            if (!this.preview) {
              this.questionnaireAnswerService.nextQuestion(this.questionnaireAnswer.id, questionId)
            }
          }
        }
        this.presentedFieldIndex = index
        this.reRenderSingleQuestion()
      } else {

        const questionElement = document.querySelector(`.question_${questionId}`)
        if (questionElement) {
          questionElement.scrollIntoView()
        }

      }
    },
    handleScroll() {

      // Update scroll percentage
      this.scrolled = parseFloat(
        this.$utils.browser.getScrollPercent()
      ).toFixed(0)
    },
    checkIfAnswerIsEmpty(answer) {
      const answerValue = this._.get(answer, 'value')
      return (
        this._.isArray(answerValue) ?
          answerValue.every(
            (value) =>  {
              if (!this._.isString(value) && 'answer' in value) {
                return this.isEmpty(value.answer)
              }
              return this.isEmpty(value)
            }
          )
          : this.isEmpty(answerValue)
      )
    },
    updateQuestionStatus(id, status) {
      const position = this.questionStates.findIndex((state) => state.id === id)

      if (position === -1) {
        this.questionStates.push({
          'id': id,
          'status': status
        })
      } else {
        this.questionStates[position]['status'] = status
      }

      this.panelQuestionKey++
    },
    questionStatusClass(question) {
      const status = this.getQuestionStatus(question)

      if (status === QUESTION_STATUS.ANSWERED) {
        return 'questionnaire-square-answered'
      } else if (status === QUESTION_STATUS.ERROR_TO_SAVE) {
        return 'questionnaire-square-error-to-save'
      }
    },
    getQuestionStatus(question) {
      const id = this._.get(question, 'id')
      const position = this.questionStates.findIndex((state) => state.id === id)

      if (position === -1) {
        return QUESTION_STATUS.NOT_ANSWERED
      } else {
        return this._.get(this.questionStates[position], 'status')
      }
    },
    startTemplateConn() {

      const templateRef = this.$refs.templateRef
      window.addEventListener('message', (event) => {
        console.log('event...', event)

        if (event.data.type === 'picked_option') {

          this.questionnaireAnswerService
            .checkQuestionAnswer(this.questionnaire.id, event.data.data.questionId, {
              options_id: [event.data.data.optionId]
            }).then(response => {
              console.log('checkQuestionAnswer...', response)

              const answer = {
                answer_id: this.questionnaireAnswer.id,
                user_id: this.user_id,
                item_id: event.data.data.questionId,
                value: event.data.data.optionId
              }

              console.log('answer...', answer)

              this.enqueueAnswer(answer)

              templateRef.contentWindow.postMessage({
                type: 'option_verified',
                data: {
                  optionId: event.data.data.optionId,
                  selected: response.result
                }
              }, '*')
            }, error => {
              templateRef.contentWindow.postMessage({
                type: 'option_verified',
                data: {
                  error: 'Check question answer error'
                }
              }, '*')
            })

        } else if (event.data.type === 'ready') {
          templateRef.contentWindow.postMessage({
            type: 'game_data',
            data: {
              redirect_url: `${process.env.VUE_APP_API_BASE_URL}/#/questionnaires/answers`,
              system_logo: this.systemLogo(),
              organization_logo: this.organizationLogo ? this.organizationLogo : null,
              questionnaire_data: this.questionnaire.data
            }
          }, '*')

        }

      }, false)
    },
    // -- VOICE CONTROL START
    speechInstructions() {
      this.showVoiceControlConfirmation = true
      this.$utils.browser.speak(this.$t('instrucoes'))
    },
    speechStartQuestionnaire() {
      this.startQuestionnaire()
    },
    speechNextQuestion() {
      this.nextQuestion()
    },
    speechPreviousQuestion() {
      this.previousQuestion()
    },
    speechFinishQuestionnaire() {
      this.finishInterval()
    },
    speechReadQuestion() {
      if ((this.started || this.devMod) && this.presentedField && this.presentedField.data) {
        const questionTitle = this.html2text(this.presentedField.data.title)
        this.$utils.browser.speak(questionTitle)
        console.log('speech-read-question...', questionTitle)
      }
    },
    speechSelectOption(event) {
      if (this.presentedField && this.presentedField.data && this.presentedField.data.options) {
        console.log('optionIndex', event.detail.optionIndex)
        console.log('options', this.presentedField.data.options)

        if (event.detail.optionIndex <= this.presentedField.data.options.length) {
          const option = this.presentedField.data.options[event.detail.optionIndex - 1]
          console.log('option...', option)
          this.eventBus.$emit('voice-control-select-option', option)
        }

      }
    },
    speechReadOptions() {
      if (this.started && this.presentedField && this.presentedField.data && this.presentedField.data.options) {
        const options = this.presentedField.data.options
        options.forEach((option, index) => {
          this.$utils.browser.speak(this.$t('opcao-index-1-option-label', [index + 1, option.label]))
        })
      }
    },
    startVoiceControl() {

      if (this.voiceControlEnabled) {

        if (this.speechRecognitionProvider === null) {
          this.speechRecognitionProvider = new SpeechRecognitionProvider()
        }

        this.speechRecognitionProvider.start()

        window.addEventListener('speech-instructions', this.speechInstructions)

        window.addEventListener('speech-start-questionnaire', this.speechStartQuestionnaire)

        window.addEventListener('speech-next-question', this.speechNextQuestion)

        window.addEventListener('speech-previous-question', this.speechPreviousQuestion)

        window.addEventListener('speech-finish-questionnaire', this.speechFinishQuestionnaire)

        window.addEventListener('speech-read-question', this.speechReadQuestion)

        window.addEventListener('speech-read-options', this.speechReadOptions)

        window.addEventListener('speech-select-option', this.speechSelectOption(event))
      }
      // -- VOICE CONTROL END
    },
    stopVoiceControl() {
      if (this.voiceControlEnabled) {
        window.removeEventListener('speech-instructions', this.speechInstructions)
        window.removeEventListener('speech-start-questionnaire', this.speechStartQuestionnaire)
        window.removeEventListener('speech-next-question', this.speechNextQuestion)
        window.removeEventListener('speech-previous-question', this.speechPreviousQuestion)
        window.removeEventListener('speech-finish-questionnaire', this.speechFinishQuestionnaire)
        window.removeEventListener('speech-read-question', this.speechReadQuestion)
        window.removeEventListener('speech-select-option', this.speechSelectOption)
        this.speechRecognitionProvider.stop()
        this.speechRecognitionProvider = null
      }
    },
    voiceControlStateChange(state) {
      console.log('voiceControlStateChange', state)
      this.voiceControlActivated = state
      this.showVoiceControlConfirmation = false

      let text = this.$t('controle-por-voz-desativado')
      if (this.voiceControlActivated) {
        text = this.$t('controle-por-voz-ativado')
        this.startVoiceControl()
      } else {
        this.stopVoiceControl()
      }
      console.log('text', text)
      this.$utils.browser.speak(text)
    },
    sectionBackground(field) {
      let classes = `list-item question_${field.id}`
      if (field && (field.type === 'section' && this.isEmpty(field.data.description))) {
        classes = `${classes} section-background`
      }
      return classes
    },
    scrollToTop() {
      window.scrollTo(0, 0)
      const popupContent = this.$el.offsetParent.querySelector('.vs-popup--content')
      if (popupContent) {
        popupContent.scrollTo(0, 0)
      }
    },

  },
  // --- METHODS END ---

  beforeMount() {
    this.runDebounced = this._.debounce((callback, ...params) => {
      return callback(...params)
    }, 600)
    this.screenfullInstance = screenfull
    this.$root.$on('enqueueAnswer', this.enqueueAnswer)
    this.service = ContentQuestionnaireService.build(this.$vs)
    this.awsService = AwsService.build(this.$vs)
    this.questionnaireAnswerService = QuestionnaireAnswerService.build(this.$vs)
    this.organizationService = OrganizationService.build(this.$vs)

    if (!this.isPracticalTest) {
      const user = JSON.parse(localStorage.getItem('userInfo'))
      if (user) {
        this.user_id = user.id
        this.getAnswerData()
      } else {
        this.$router.push('/login')
      }
    }
  },
  beforeDestroy() {
    this.$root.$off('enqueueAnswer', this.enqueueAnswer)
    this.cancelProctoringInit()
  },
  destroyed() {
    this.$store.commit('formAnswer/ENABLE_FORM_ANSWER')
    window.removeEventListener('beforeunload', this.leave)
    this.stopVoiceControl()
    if (!this.isEmpty(this.camPeer)) {
      this.camPeer.destroy()
      this.camPeer = null
      this.supportConnection = null
    }
    this.$root.$off('webOnline', this.resumeIntervalQueue)
    this.$root.$off('webOffline', this.pauseIntevalQueue)
  },
  created() {
    this.isValidBrowser = this.proctoringCompability()
    this.questionTimer = 0
    window.addEventListener('scroll', this.handleScroll)
  },
  mounted() {
    this.updateWidth(this.$store.getters.windowWidth)
    const enterQuestionnaireLink = new EnterQuestionnaireLink({userAgent: navigator.userAgent})
    this.$root.$emit('enterQuestionnaireLink', enterQuestionnaireLink)
    this.$store.commit('common/DISABLED_CONTEXT_MENU', true)
    this.$root.$on('webOnline', this.resumeIntervalQueue)
    this.$root.$on('webOffline', this.pauseIntevalQueue)

    if (this.isPracticalTest) {
      this.startPracticalTest()
    }
  }
}
</script>

<style lang="scss" scoped>

.rounded-full {
  max-width: 10px;
  min-width: 10px;
}

</style>

<style lang="scss">

.fixed-content {
  top: 0.75rem;
  bottom:1rem;
  position:fixed;
  overflow-y:auto;
  overflow-x:hidden;
  & .con-vs-card {
    margin-bottom: 0px;
  }
}

.proctoring-image-guide {
  & img {
    --border-opacity: 1;
    border-color: rgba(var(--vs-primary), 1);
    @apply rounded border-2 border-solid
  }
}


// -------------- Magic --------------------

.form-answer {
  @media screen and (min-width: 576px) {
    --option-text-ratio: 0.7;
  }
  @media screen and (min-width: 972px) {
    --option-text-ratio: 0.85;
  }
  --option-text-ratio: 0.6;

  &.active-sidebar {
    @media screen and (min-width: 576px) {
      --option-text-ratio: 0.5;
    }
    --option-text-ratio: 0.4;
  }
}
// -----------------------------------------


.__answer-card {
  & .vx-card__body {
    @media screen and (max-width: 360px) {
      padding: 0px !important;
    }
  }
}

._form-answer._mobile-navigation-bar {
  @apply transition-all;
  @apply duration-1000;
  @apply ease-out;
  // @apply delay-100;
  & .vx-card__body {
    padding: 0.5rem 1rem;
  }
  padding-left: 1rem;
  padding-right: 1rem;
  @apply sticky;
  width: 100%;
}

._form-answer.__navigation-warning-popup .vs-popup--title > * {
  color: rgba(var(--vs-danger), 1);
}

.list-item {
  background-color: white;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: #fff;
}

.vs-con-input-label.is-label-placeholder {
  margin-top: 0px;
}

.vs-popup--content {
  overflow: auto;
  height: 100% !important;
}

.instruction {
  border: 2px solid white;
  border-radius: 5px;
}

.instruction-error {
  border: 2px solid red;
  border-radius: 5px;
}

.bottom-fixed {
  bottom: 0;
  position: fixed;
}

.border-bottom {
  border-bottom: 1px dashed gray;
}

.video-player {
  width: 100%;
  padding: 10px;
  position: relative;
  z-index: 999999999999;
}

.monitor-video-player {
  position: relative;
  width: 100% !important;
  left: 0;
  z-index: 999999999999;
}

.video-player-toggle {
  z-index: 999999999999999999999999999999;
  width: 200px;
  position: relative;
  height: 14px;
  background-color: #b8c2cc;
  color: white;
  align-content: center;
  cursor: pointer;
}

.video-player-monitor-toggle {
  width: 400px !important;
}

.open-call-support {
  z-index: 999999999999;

  svg {
    font-size: 18px !important;
    color: $success !important;
  }
}

.close-call-support {
  z-index: 999999999999;

  svg {
    font-size: 18px !important;
    color: $danger !important;
  }
}

.transform {
  transform: rotateY(180deg) !important;
  -webkit-transform:rotateY(180deg) !important; /* Safari and Chrome */
  -moz-transform:rotateY(180deg) !important; /* Firefox */
}

.mic-success {
  color: $success;
}

.mic-config {
  color: $warning;
}

#descriptionElement {
  padding-top: 10px;
  font-size: 14px;
}

.vs-select--options {
  z-index: 9999999999999999;
}

#detach-button-host {
  display: none !important;
}

.vs-progress--background {
  width: 50% !important;
}

.questionnaire-squares {
  width: 25px;
  height: 25px;
  margin: 2px;
  border: 2px solid;
}


.questionnaire-square-selected {
  border: 2px solid $primary !important;
}

.questionnaire-square-answered {
  background-color: #dff0d8 !important;
}

.questionnaire-square-error-to-save {
  background-color: theme('colors.red-light') !important;
}

.questionnaire-square-bookmarked {
  border: 2px solid $warning !important;
}

.question-square-bookmark-position {
  top: -0.25rem;
  right: -0.25rem;
  filter: drop-shadow(0 4px 3px rgba(255, 255, 255, 0.15)) drop-shadow(0 2px 2px rgba(255, 255, 255, 0.12));
}

.answer-saved {
  background-color: #dff0d8 !important;
}

.sidebar-panel {
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebar {

  .vs-sidebar {
    max-width: 370px;
  }

  .vs-sidebar-parent {
    color: unset !important;
    background: unset !important;
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
  }
}

.sidebar-left-button {
  font-size: 24px;
  position: sticky;
  right: 3px;
  top: 14px;
}

.sidebar-right-button {
  font-size: 20px;
  z-index: 54100;
}

.iframe-template {
  display: block;       /* iframes are inline by default */
  background: #000;
  border: none;         /* Reset default border */
  height: 100vh;        /* Viewport-relative units */
  width: 100vw;
}

.section-background {
  background-color: lightgray !important;
}

</style>
