
<template>
  <vs-row>
    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
      <div id="clockdiv">
        <div v-if="showDays">
          <span class="days">{{days}}</span>
          <div class="smalltext">{{ $t('dias') }}</div>
        </div>
        <div v-if="showHours">
          <span class="hours">{{hours}}</span>
          <div class="smalltext">{{hourLabel}}</div>
        </div>
        <div>
          <span class="minutes">{{minuts}}</span>
          <div class="smalltext">{{ $t('min') }}</div>
        </div>
        <div v-if="showSeconds">
          <span class="seconds">{{seconds}}</span>
          <div class="smalltext">{{ $t('seg') }}</div>
        </div>
      </div>
    </vs-col>
  </vs-row>
</template>

<script>

const millisecond = 1
const second = 1000 * millisecond
const minute = 60 * second
const hour = 60 * minute
const day = 24 * hour

export default {
  props: {
    time_remaining: {
      type: Number,
      default: 0
    },
    show_seconds: {
      type: Boolean,
      default: true
    },
    duration: { //In Secunds
      type: Number,
      default: 0
    },
    datetimeNow: {
      type: String,
      default: null
    }
  },
  data: () => ({
    timeInterval: null,
    secondsRemaining: 0,
    days: null,
    hours: null,
    minuts: null,
    seconds: null,
  }),
  computed: {
    hourLabel() {
      return this.$t('hora-0')
    },
    showSeconds () {
      return this.show_seconds
    },
    showDays () {
      return false //implement
    },
    showHours () {
      return false //implement
    }
  },
  methods: {
    initializeClock() {

      this.cycle()
      this.timeInterval = setInterval(this.cycle, 1000)
    },
    cycle() {
      this.formatClock(this.secondsRemaining * 1000)
      this.secondsRemaining -= 1
      this.$emit('duration', {total: this.secondsRemaining})

      if (this.secondsRemaining <= 0) {
        this.initDataLabels();
        this.$emit('zero')
        clearInterval(this.timeInterval)
      }
    },
    formatClock(t) {
      this.days = this.getDays(t)
      this.hours = this.getHours(t)
      this.minuts = this.getMinutes(t)
      this.seconds = this.getSeconds(t)

      this.hourLabel = this.hours > 1 ? this.$t('horas') : this.$t('hora-0')
    },
    getDays(t) {
      return this.withPadding(Math.floor(t / day) % 30)
    },
    getHours(t) {
      return this.withPadding(Math.floor(t / hour) % 24)
    },
    getMinutes(t) {
      return this.withPadding(Math.floor(t / minute) % 60)
    },
    getSeconds(t) {
      return this.withPadding(Math.floor(t / second) % 60)
    },
    withPadding(val) {
      return String(val).padStart(2, '0')
    },
    initDataLabels() {
      this.hours = '00'
      this.minuts = '00'
      this.seconds = '00'
    }
  },
  mounted() {
    if (this.duration < 0) {
      this.initDataLabels()
    }

    if (this.duration && this.duration > 0) {
      this.secondsRemaining = this.duration
      this.initializeClock()
    }
  },
  beforeUnmount(){
    //alert('CountDown - beforeUnmount')
  },
  unmounted(){
    //alert('CountDown - unmounted')
  },
  destroyed() {
    if (this.timeInterval) {
      clearInterval(this.timeInterval)
    }
  }
}
</script>

<style>
#clockdiv {
  font-family: sans-serif;
  color: #fff;
  display: inline-block;
  font-weight: 100;
  text-align: center;
  font-size: 20px;
  z-index: 54006;
}

#clockdiv > div {
  min-width: 55px !important;
  padding: 5px;
  background: #00bf96;
  display: inline-block;
}

#clockdiv div > span {
  padding: 5px;
  background: #00816a;
  display: inline-block;
}

.smalltext {
  font-size: 12px;
}
</style>
