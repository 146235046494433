var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [
        _c("h4", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "informacoes-gerais-questionnairename-questionnairename",
                  [_vm.questionnaireName ? "- " + _vm.questionnaireName : ""]
                )
              ) +
              " "
          ),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.questionnaire !== null,
              expression: "questionnaire !== null",
            },
          ],
          class: { "border-bottom": !_vm.start_error },
        },
        [
          "user" in _vm.questionnaire
            ? _c("span", { staticClass: "flex items-center mt-4" }, [
                _c("div", {
                  staticClass:
                    "ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success",
                }),
                _c("span", { staticClass: "text-lg" }, [
                  _vm._v(" " + _vm._s(_vm.$t("participant")) + ": "),
                  _c("b", [_vm._v(_vm._s(_vm.questionnaire.user.name))]),
                  _vm._v(" - "),
                  _c("span", { staticClass: "text-md" }, [
                    _vm._v(_vm._s(_vm.questionnaire.user.email)),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.showFaceMatchInfo
            ? _c("span", { staticClass: "flex items-center mt-4" }, [
                _c("div", {
                  staticClass:
                    "ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-warning",
                }),
                _c("span", { staticClass: "text-lg text-warning" }, [
                  _vm._v(" " + _vm._s(_vm.showFaceMatchInfo) + " "),
                ]),
              ])
            : _vm._e(),
          _vm.showLivenessInfo
            ? _c("span", { staticClass: "flex items-center mt-4" }, [
                _c("div", {
                  staticClass:
                    "ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-warning",
                }),
                _c("span", { staticClass: "text-lg text-warning" }, [
                  _vm._v(" " + _vm._s(_vm.showLivenessInfo) + " "),
                ]),
              ])
            : _vm._e(),
          _vm.start_error
            ? _c(
                "span",
                {
                  class: _vm.startErrorStyle(),
                  on: { click: _vm.startErrorClick },
                },
                [
                  _c("div", {
                    staticClass:
                      "ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-danger",
                  }),
                  _c("span", { staticClass: "text-lg text-danger" }, [
                    _c("b", [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.start_error) },
                      }),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.showDurationInfo && !_vm.startError
            ? _c("span", { staticClass: "flex items-center mt-4" }, [
                _c("div", {
                  staticClass:
                    "ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success",
                }),
                _c("span", { staticClass: "text-lg" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        (_vm.isReturning
                          ? _vm.$t("Remainingtime")
                          : _vm.$t("Duration")) + ":"
                      ) +
                      " "
                  ),
                  _c("b", [_vm._v(_vm._s(_vm.durationClockInfo))]),
                ]),
              ])
            : _vm._e(),
          _vm.showDurationInfo &&
          !_vm.startError &&
          _vm.showDurationShortageMessage
            ? _c("span", { staticClass: "flex items-center ml-8 mt-0" }, [
                _c(
                  "span",
                  { staticClass: "font-medium text-warning" },
                  [
                    _c("font-awesome-icon", { attrs: { icon: "arrow-up" } }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "atencao-duracao-reduzida-nao-e-possivel-ultrapassar-o-periodo-do-questionario"
                          )
                        ) +
                        " "
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.periodEntranceFmt
            ? _c("span", { staticClass: "flex items-center mt-4" }, [
                _c("div", {
                  staticClass:
                    "ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success",
                }),
                _c("span", { staticClass: "periodInfoClass text-lg" }, [
                  _vm._v(" " + _vm._s(_vm.$t("periodo-do-questionario")) + " "),
                  _c("b", [
                    _vm._v(_vm._s(_vm.questionnaire.period_entrance_fmt)),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.entranceTimeLimitRemainderInfo !== null &&
          !_vm.startError &&
          _vm.withinPeriod
            ? _c(
                "span",
                { staticClass: "flex items-center mt-4 text-warning" },
                [
                  _c("div", {
                    staticClass:
                      "ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success",
                  }),
                  _c("span", { staticClass: "text-lg" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._.get(
                            _vm.entranceTimeLimitRemainderInfo,
                            "label",
                            ""
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("span", { staticClass: "pl-2 font-bold text-lg" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._.get(
                            _vm.entranceTimeLimitRemainderInfo,
                            "time",
                            ""
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.showAnswersQuantityInfo
            ? _c("span", { staticClass: "flex items-center mt-4" }, [
                _c("div", {
                  staticClass:
                    "ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success",
                }),
                _c("span", {
                  directives: [
                    {
                      name: "html-safe",
                      rawName: "v-html-safe",
                      value: _vm.showAnswersQuantityInfo,
                      expression: "showAnswersQuantityInfo",
                    },
                  ],
                  staticClass: "text-lg",
                }),
              ])
            : _vm._e(),
          _vm.showAnswersInfo
            ? _c("span", { staticClass: "flex items-center mt-4" }, [
                _c("div", {
                  staticClass:
                    "ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success",
                }),
                _c("span", { staticClass: "text-lg" }, [
                  _vm._v(_vm._s(_vm.showAnswersInfo)),
                ]),
              ])
            : _vm._e(),
          _vm.showMultipleAttemptsInfo
            ? _c("span", { staticClass: "flex items-center mt-4" }, [
                _c("div", {
                  staticClass:
                    "ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success",
                }),
                _c("span", { staticClass: "text-lg" }, [
                  _vm._v(_vm._s(_vm.showMultipleAttemptsInfo)),
                ]),
              ])
            : _vm._e(),
          _vm.showViewResultsInfo
            ? _c("span", { staticClass: "flex items-center mt-4" }, [
                _c("div", {
                  staticClass:
                    "ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success",
                }),
                _c("span", { staticClass: "text-lg" }, [
                  _vm._v(_vm._s(_vm.showViewResultsInfo)),
                ]),
              ])
            : _vm._e(),
          _vm.showLeaveInfo && !_vm.startError
            ? _c(
                "span",
                { staticClass: "flex items-center mt-4 mb-6 leave-info" },
                [
                  _c("div", {
                    staticClass:
                      "ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success",
                  }),
                  _c("span", { staticClass: "text-lg" }, [
                    _vm._v(_vm._s(_vm.showLeaveInfo)),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.showDisallowQuestionReturn && !_vm.startError
            ? _c(
                "span",
                { staticClass: "flex items-center mt-4 mb-6 leave-info" },
                [
                  _c("div", {
                    staticClass:
                      "ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success",
                  }),
                  _c("span", { staticClass: "text-lg" }, [
                    _vm._v(_vm._s(_vm.showDisallowQuestionReturn)),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.showForceFullscreen && !_vm.startError
            ? _c(
                "span",
                { staticClass: "flex items-center mt-4 mb-6 leave-info" },
                [
                  _c("div", {
                    staticClass:
                      "ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success",
                  }),
                  _c("span", { staticClass: "text-lg" }, [
                    _vm._v(_vm._s(_vm.showForceFullscreen)),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.showCanPause && !_vm.startError
            ? _c(
                "span",
                { staticClass: "flex items-center mt-4 mb-6 leave-info" },
                [
                  _c("div", {
                    staticClass:
                      "ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success",
                  }),
                  _c("span", { staticClass: "text-lg" }, [
                    _vm._v(_vm._s(_vm.showForceFullscreen)),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.showIsPaused && !_vm.startError
            ? _c(
                "span",
                { staticClass: "flex items-center mt-4 mb-6 leave-info" },
                [
                  _c("div", {
                    staticClass:
                      "ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success",
                  }),
                  _c("span", { staticClass: "text-lg" }, [
                    _vm._v(_vm._s(_vm.showForceFullscreen)),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.showHasEntranceTime && !_vm.startError
            ? _c(
                "span",
                { staticClass: "flex items-center mt-4 mb-6 leave-info" },
                [
                  _c("div", {
                    staticClass:
                      "ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success",
                  }),
                  _c("span", { staticClass: "text-lg" }, [
                    _vm._v(_vm._s(_vm.showForceFullscreen)),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.showHasVoiceControl && !_vm.startError
            ? _c(
                "span",
                { staticClass: "flex items-center mt-4 mb-6 leave-info" },
                [
                  _c("div", {
                    staticClass:
                      "ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success",
                  }),
                  _c("span", { staticClass: "text-lg" }, [
                    _vm._v(_vm._s(_vm.showVoiceControl)),
                  ]),
                  _c(
                    "span",
                    { staticClass: "ml-2" },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "ml-4",
                          attrs: { type: "border", color: "danger" },
                          on: { click: _vm.toggleVoiceControl },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.voiceControlActivated
                                  ? _vm.$t("desativar-controle-por-voz")
                                  : _vm.$t("ativar-controle-por-voz")
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.isProctored
            ? _c("span", { staticClass: "flex items-center mt-4" }, [
                _c("div", {
                  staticClass:
                    "ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success",
                }),
                _c(
                  "span",
                  { staticClass: "text-lg" },
                  [
                    _c("b", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("e-importante-fazer-o-teste-de-conectividade")
                        )
                      ),
                    ]),
                    _c(
                      "vs-button",
                      {
                        staticClass: "ml-4",
                        attrs: { type: "border", color: "warning" },
                        on: { click: _vm.connectivityTest },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("teste-de-conectividade")) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "vs-popup",
        {
          directives: [
            {
              name: "esc",
              rawName: "v-esc",
              value: _vm.closeProctoringTest,
              expression: "closeProctoringTest",
            },
          ],
          attrs: {
            title: _vm.$t("teste-de-conectividade"),
            active: _vm.showProctoringTest,
            fullscreen: true,
          },
          on: {
            "update:active": function ($event) {
              _vm.showProctoringTest = $event
            },
          },
        },
        [
          _vm.showProctoringTest
            ? _c("ProctoringTest", { ref: "proctoringTestRef" })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }